import { PermissionRequestList, TPermissionRequestDetail } from '@verifime/components';
import { hooksApi } from './api';

export default function PermissionRequests({
  userEmailAddress,
  userId,
  username,
}: {
  userEmailAddress?: string;
  userId?: string;
  username?: string;
}) {
  if (!userEmailAddress && !userId && !username) {
    throw new Error('At least one of userEmailAddress, userId or username must be provided');
  }

  const { data: permissionRequests, isLoading } = hooksApi.useGetV1permissionrequest({
    queries: { email: userEmailAddress, userId, username },
  });

  if (!permissionRequests) {
    return null;
  }

  const permissionRequestsToDisplay: TPermissionRequestDetail[] = permissionRequests.map(
    (permissionRequest) => {
      return {
        organisationName: permissionRequest.organisationName,
        parentOrganisationName: permissionRequest.parentOrganisationName,
        createdDateTime: permissionRequest.createdDateTime,
        completedDateTime: permissionRequest.completedDateTime,
      };
    },
  );

  return (
    <PermissionRequestList
      isLoadingPermissionRequests={isLoading}
      permissionRequests={permissionRequestsToDisplay}
    />
  );
}
