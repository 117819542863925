export enum FileType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ANY_IMAGE = 'image/*',
}

export interface TUploadFile {
  id: string;
  file: File; // Original File object
  url?: string; // URL for preview and final uploaded file
  progress?: number; // Upload progress
  uploading?: boolean; // Upload status
  error?: string; // Error message if any
}

export interface TInitialFile {
  url: string;
  id?: string;
  name?: string;
}

export interface TFileUploaderProps {
  multiple?: boolean;
  maxFileCount?: number;
  maxFileSize?: number;
  onUpload: (
    file: File,
    onProgress: (progress: number) => void,
  ) => Promise<{ url: string; id?: string }>;
  onDelete?: (file: TUploadFile) => Promise<void>;
  onBeforeUpload?: (files: File[]) => boolean | Promise<boolean>;
  initialFiles?: TInitialFile[];
  hideUploaderAfterUpload?: boolean;
  hideUploaderOnInitialFiles?: boolean;
  onUploadSuccess?: (files: TUploadFile[]) => void;
  onUploadError?: (error: string) => void;
  onFileRemove?: (file: TUploadFile) => void;
  onFileRemoveError?: (error: string) => void;
  CustomPreview?: React.ComponentType<TFilePreviewProps>;
  CustomProgressBar?: React.ComponentType<TProgressBarProps>;
  acceptedFileTypes?: FileType[];
  onLimitReached?: (reachedLimit: {
    isMaxFileCountReached: boolean;
    isMaxFileSizeReached: boolean;
    uploadedFiles: TUploadFile[];
    attemptedFiles: File[];
    limits: {
      maxFileCount?: number;
      maxFileSize?: number;
    };
  }) => void;
}

export interface TFilePreviewModalProps {
  open: boolean;
  onClose: () => void;
  fileUrl?: string;
  isPDF?: boolean;
}

export interface TFilePreviewProps {
  file: TUploadFile;
  onRemove: () => void;
  CustomProgressBar?: React.ComponentType<TProgressBarProps>;
}

export interface TProgressBarProps {
  progress: number;
  isUploaded: boolean;
}
