import { FileType, TUploadFile } from './types';

export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const createUploadFile = async (file: File): Promise<TUploadFile> => {
  return {
    id: Math.random().toString(36).slice(2, 9),
    file,
    url: URL.createObjectURL(file),
    progress: 0,
    uploading: true,
  };
};

export const isWildcardType = (type: FileType): boolean => {
  return type.includes('/*');
};

export const getFileTypeDisplay = (type: FileType): string => {
  if (type === FileType.ANY_IMAGE) return 'Images';
  if (type === FileType.PDF) return 'PDF';
  return type.split('/')[1].toUpperCase();
};

export const convertUrlToBlob = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url, { mode: 'cors' });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error converting image to blob:', error);
    return url; // Fallback to original URL if conversion fails
  }
};
