import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { alpha, Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React, { useEffect, useState } from 'react';
import DefaultProgressBar from './DefaultProgressBar';
import { FilePreviewModal } from './FilePreviewModal';
import { TFilePreviewProps } from './types';
import { convertUrlToBlob, formatFileSize } from './utils';

export const DefaultFilePreview: React.FC<TFilePreviewProps> = ({
  file,
  onRemove,
  CustomProgressBar,
}) => {
  const theme = useTheme();
  const isPDF = file.file.type === 'application/pdf';
  const [blobUrl, setBlobUrl] = useState<string | undefined>(undefined);
  const [isPreviewReady, setIsPreviewReady] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const ProgressBar = CustomProgressBar || DefaultProgressBar;

  useEffect(() => {
    let newBlobUrl: string | undefined = undefined;
    setIsPreviewReady(false);

    const setupPreview = async () => {
      try {
        if (file.url && file.file.type.startsWith('image/')) {
          newBlobUrl = await convertUrlToBlob(file.url);
          setBlobUrl(newBlobUrl);
        } else {
          setBlobUrl(file.url);
        }
        setIsPreviewReady(true);
      } catch (error) {
        console.error('Failed to setup preview:', error);
        setBlobUrl(file.url);
        setIsPreviewReady(true);
      }
    };

    setupPreview();

    return () => {
      if (newBlobUrl && newBlobUrl.startsWith('blob:')) {
        URL.revokeObjectURL(newBlobUrl);
      }
    };
  }, [file.id, file.url, file.file.type]);

  const handleFileRemove = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsRemoving(true);

    onRemove();
    // Delay the cleanup and removal to allow for fade-out animation
    setTimeout(() => {
      if (blobUrl && blobUrl.startsWith('blob:')) {
        URL.revokeObjectURL(blobUrl);
      }
      setBlobUrl(undefined);
    }, 0); // Match the transition duration
  };

  const handlePreviewClick = () => {
    if (!file.uploading && !file.error) {
      setIsPreviewOpen(true);
    }
  };

  const handleDownload = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (file.url) {
      try {
        // Start download
        const response = await fetch(file.url);
        const blob = await response.blob();

        // Create blob URL
        const blobUrl = window.URL.createObjectURL(blob);

        // Create temporary link and trigger download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.file.name; // Use original filename
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Download failed:', error);
        // Fallback to opening in new tab if download fails
        window.open(file.url, '_blank');
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: tokens.size2xs,
          height: tokens.size2xs,
          borderRadius: tokens.borderRadiusSm,
          overflow: 'hidden',
          cursor: file.uploading || file.error ? 'default' : 'pointer',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderWidth: file.error ? tokens.spacing3xs : tokens.spacing4xs,
          borderStyle: 'solid',
          borderColor: file.error ? 'error.main' : 'divider',
          '&:hover .actions': {
            opacity: 1,
          },
          opacity: isPreviewReady && !isRemoving ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
          pointerEvents: isRemoving ? 'none' : 'auto',
        }}
        onClick={handlePreviewClick}
      >
        {/* Preview */}
        {isPDF ? (
          <Box
            sx={{
              width: '100%',
              height: `calc(${tokens.size2xs} - ${tokens.spacing4xl})`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              flexShrink: 0,
            }}
          >
            <PictureAsPdfIcon
              sx={{
                fontSize: tokens.fontSize7xl,
                color: file.error ? 'error.main' : 'primary.main',
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: `calc(${tokens.size2xs} - ${tokens.spacing4xl})`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'white',
              flexShrink: 0,
            }}
          >
            {isPreviewReady && blobUrl && !isRemoving && (
              <Box
                component="img"
                src={blobUrl}
                alt={file.file.name}
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  p: tokens.spacingXs,
                }}
              />
            )}
          </Box>
        )}

        {/* File Info */}
        <Stack
          sx={{
            height: tokens.spacing4xl,
            flexShrink: 0,
            justifyContent: 'space-between',
            borderTop: '1px solid',
            borderColor: 'divider',
            gap: tokens.spacingXs,
            padding: tokens.spacingXs,
          }}
        >
          <Stack gap={tokens.spacingXs}>
            <Typography noWrap title={file.file.name} variant="body2">
              {file.file.name}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {formatFileSize(file.file.size)}
            </Typography>
          </Stack>
        </Stack>

        {/* Upload Progress Overlay */}
        {file.uploading && !file.error && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: alpha(theme.palette.grey[100], 0.8),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ProgressBar progress={file.progress || 0} isUploaded={!file.uploading} />
          </Box>
        )}

        {/* Error Overlay */}
        {file.error && (
          <Stack
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: alpha(theme.palette.error.main, 0.3),
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ErrorOutlineIcon
              sx={{
                fontSize: tokens.fontSize7xl,
                color: 'error.main',
              }}
            />
            <Typography color="error">{file.error}</Typography>
          </Stack>
        )}

        {/* Actions */}
        <Box
          className="actions"
          sx={{
            position: 'absolute',
            top: tokens.spacingXs,
            right: tokens.spacingXs,
            display: 'flex',
            gap: tokens.spacingXs,
            opacity: 0,
            transition: 'opacity 0.2s',
            bgcolor: 'grey.100',
            borderRadius: tokens.spacing2xs,
            p: tokens.spacing2xs,
            zIndex: 1,
          }}
        >
          {!file.uploading && !file.error && (
            <IconButton
              size="small"
              onClick={handleDownload}
              sx={{
                color: 'primary.main',
                '&:hover': {
                  bgcolor: 'grey.300',
                },
              }}
            >
              <DownloadIcon />
            </IconButton>
          )}
          <IconButton
            size="small"
            onClick={handleFileRemove}
            sx={{
              color: 'error.main',
              '&:hover': {
                bgcolor: 'grey.300',
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      {isPreviewReady && !isRemoving && (
        <FilePreviewModal
          open={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          fileUrl={blobUrl || file.url}
          isPDF={isPDF}
        />
      )}
    </>
  );
};
