import { Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';

export const IdScanComplete = () => {
  return (
    <Stack gap={tokens.spacingBase}>
      <Typography variant="h6">ID scan complete – check your information: </Typography>
      <Typography variant="h6">
        Please carefully check that your details captured below are exactly as shown on your ID.
        Please update any fields that are not correct, then press “continue” to proceed.
      </Typography>
    </Stack>
  );
};
