import { GenerateFormFields_New, getFieldsRules_New, useCustomForm } from '@verifime/components';
import { stringUtils } from '@verifime/utils';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { generateFormFieldsAndRules } from './formFieldConfigs';
import { buildOcrData, TOcrData, TSupportedOcrDocumentType } from './utils';

export const OcrDocumentEditor = ({
  initialData,
  documentType,
  onFormChange,
}: Readonly<{
  initialData: TOcrData;
  documentType: TSupportedOcrDocumentType;
  onFormChange?: (isValid: boolean, data: TOcrData) => void;
}>) => {
  const documentData = initialData?.[stringUtils.lowerCaseFirstLetter(documentType)];
  const formFieldsAndRules = useMemo(
    () => generateFormFieldsAndRules(documentType, documentData),
    [documentType, documentData],
  );
  const methods = useCustomForm<Record<string, any>>({
    defaultValues: initialData || {},
    schema: getFieldsRules_New(formFieldsAndRules),
  });
  const {
    setValue,
    getValues,
    trigger,
    watch,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (Object.keys(documentData || {}).length < 1) {
      return;
    }
    Object.entries(documentData).forEach(([k, v]) => setValue(k, v));
  }, [documentData, setValue]);

  useEffect(() => {
    const validateAndNotify = async () => {
      await trigger();
      onFormChange?.(isValid, buildOcrData(documentType, getValues()));
    };
    validateAndNotify();
  }, [trigger, getValues, isValid, onFormChange, documentType]);

  // Watch for subsequent changes
  useEffect(() => {
    const subscription = watch(async (formData) => {
      await trigger();
      onFormChange?.(isValid, buildOcrData(documentType, formData));
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger, isValid, onFormChange, documentType]);

  return (
    <FormProvider {...methods}>
      <GenerateFormFields_New
        formFieldsAndRules={formFieldsAndRules}
        control={methods.control}
        errors={methods.formState.errors}
        formFieldsWithDefaultValues={documentData}
      />
    </FormProvider>
  );
};
