import { Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { documentLabelMapping, TSupportedOcrDocumentType } from './utils';

export const CheckYourFiles = ({ documentType }: { documentType: TSupportedOcrDocumentType }) => {
  const documentLabel = documentLabelMapping[documentType];
  if (!documentLabel) {
    return;
  }
  return (
    <Stack gap={tokens.spacingBase}>
      <Typography variant="h6">Check your files – {documentLabel} not detected</Typography>
      <Typography variant="h6">
        It appears that the files you uploaded do not contain your {documentLabel}.
      </Typography>
      <Typography variant="h6">
        Please re-upload ensuring the files you provide only contain your {documentLabel} and
        nothing else.
      </Typography>
    </Stack>
  );
};
