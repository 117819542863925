import { Stack, Typography } from '@mui/material';
import { TPerson } from '@verifime/api-definition';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';
import { TOcrData } from './OcrEditor';
import {
  driverLicenceFormFields,
  medicareCardFormFields,
  passportFormFields,
} from './OcrEditor/formFieldConfigs';

export type DiffResultkeys = 'name';

export type TDiffResult = {
  [key in DiffResultkeys]: {
    step1: { customerName: string; firstName: string; middleName?: string; familyName: string };
    ocrData: {
      value: string;
      label: string;
    };
  };
};

export function diff(customer: TPerson, formData: TOcrData): TDiffResult {
  const { customerName, firstName, middleName, familyName } = customer || {};
  const { name } = getComparePropertiesFromFormData(formData);
  if (!name) {
    return;
  }

  const diffResult = {} as TDiffResult;
  const lowerName = (name.value ?? '').toLocaleLowerCase();

  // Due to complexity of different type of identity document all has different treatments for middle name,
  // thus we don't use middle while comparing
  if (
    !(
      lowerName.startsWith(firstName.toLocaleLowerCase()) &&
      lowerName.endsWith(familyName.toLocaleLowerCase())
    )
  ) {
    diffResult.name = {
      step1: { customerName, firstName, middleName, familyName },
      ocrData: name,
    };
  }
  return diffResult;
}

export function getComparePropertiesFromFormData(formData: TOcrData) {
  switch (formData.type) {
    case 'DriverLicence': {
      return {
        name: {
          value: formData.driverLicence.licenceName,
          label: driverLicenceFormFields.licenceName.label,
        },
      };
    }
    case 'MedicareCard': {
      return {
        name: {
          value: formData.medicareCard.medicareCardFullName,
          label: medicareCardFormFields.medicareCardFullName.label,
        },
      };
    }
    case 'Passport': {
      return {
        name: {
          value: formData.passport.passportName,
          label: passportFormFields.passportName.label,
        },
      };
    }
    default: {
      return {};
    }
  }
}

export function buildDiffMessages(diffResult: TDiffResult): ReactNode[] {
  const diffMessages: ReactNode[] = [];
  if (Object.keys(diffResult).length < 1) {
    return diffMessages;
  }
  Object.entries(diffResult).forEach(([key, value]) => {
    diffMessages.push(
      <Typography>
        Your <b>{value.ocrData.label}</b> does not match your account <b>{key}</b>.
      </Typography>,
    );
  });

  const lastDiffMessage = diffMessages.pop();
  const attachedTailing = (
    <Stack gap={tokens.spacingXs}>
      {lastDiffMessage}
      <Typography>
        If you proceed we will update your account <b>{Object.keys(diffResult).join(',')}</b>
      </Typography>
    </Stack>
  );
  diffMessages.push(attachedTailing);
  return diffMessages;
}

export function updatePersonAccordingDiffResult(person: TPerson, diffResult: TDiffResult) {
  // Update person name
  if (diffResult.name) {
    const customerName = diffResult.name.ocrData.value;
    if (!customerName) {
      return;
    }
    const [firstName, middleName, lastName] = customerName.split(/\s+/);
    const computedMiddleName = !lastName ? undefined : middleName;
    const computedLastName = !lastName ? middleName : lastName;
    person.customerName = customerName;
    person.firstName = firstName;
    person.middleName = computedMiddleName;
    person.familyName = computedLastName;
  }
}
