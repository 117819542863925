import {
  GenerateFormFields_New,
  getFieldsRules_New,
  TFormFieldsAndRules_New,
  useCustomForm,
} from '@verifime/components';
import { stringUtils } from '@verifime/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { medicareCardFieldsAndRules, medicareCardOwnersFieldsAndRules } from './formFieldConfigs';
import { MedicareCardOwnersInputs } from './MedicareCardOwnersInputs';
import {
  buildOcrData,
  objectMedicareCardOwnersToArray,
  TOcrData,
  TSupportedOcrDocumentType,
} from './utils';

export const OcrMedicareCard = ({
  initialData,
  documentType,
  onFormChange,
}: Readonly<{
  initialData: TOcrData;
  documentType: TSupportedOcrDocumentType;
  onFormChange?: (isValid: boolean, data: TOcrData) => void;
}>) => {
  /**
   * TODO: We need to further clean the code to reduce the duplication
   */
  const [fieldsAndRules, setFieldsAndRules] = useState<TFormFieldsAndRules_New>(
    medicareCardFieldsAndRules,
  );
  const documentData = initialData?.[stringUtils.lowerCaseFirstLetter(documentType)];
  const medicareCardOwnersData = initialData?.medicareCardOwners;
  const medicareCardOwners = useMemo(
    () => objectMedicareCardOwnersToArray(medicareCardOwnersData),
    [medicareCardOwnersData],
  );
  const methods = useCustomForm<Record<string, any>>({
    defaultValues: documentData || {},
    schema: getFieldsRules_New({ ...fieldsAndRules, ...medicareCardOwnersFieldsAndRules }),
  });
  const {
    setValue,
    getValues,
    trigger,
    watch,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (medicareCardOwners?.length < 1) {
      setFieldsAndRules({ ...medicareCardFieldsAndRules, ...medicareCardOwnersFieldsAndRules });
    }
  }, [medicareCardOwners]);

  useEffect(() => {
    if (Object.keys(initialData || {}).length < 1) {
      return;
    }
    Object.entries(initialData).forEach(([k, v]) => setValue(k, v));
  }, [initialData, setValue]);

  useEffect(() => {
    const validateAndNotify = async () => {
      await trigger();
      onFormChange?.(isValid, buildOcrData(documentType, getValues()));
    };
    validateAndNotify();
  }, [trigger, getValues, isValid, onFormChange, fieldsAndRules, documentType]);

  // Watch for subsequent changes
  useEffect(() => {
    const subscription = watch(async (formData) => {
      await trigger();
      onFormChange?.(isValid, buildOcrData(documentType, formData));
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger, isValid, onFormChange, documentType]);

  const handleOnOwnerSelected = useCallback(async () => {
    await trigger();
    onFormChange?.(isValid, buildOcrData(documentType, getValues()));
  }, [documentType, getValues, isValid, onFormChange, trigger]);

  return (
    <FormProvider {...methods}>
      <GenerateFormFields_New
        formFieldsAndRules={fieldsAndRules}
        control={methods.control}
        errors={methods.formState.errors}
        formFieldsWithDefaultValues={documentData}
      />
      {medicareCardOwners && (
        <MedicareCardOwnersInputs
          medicareCardOwners={medicareCardOwners}
          onOwnerSelected={handleOnOwnerSelected}
        />
      )}
    </FormProvider>
  );
};
