import { api, apiSchemas, TLivenessTransactionStatus } from '@verifime/api-definition';
import { enqueErrorNotification, enqueSuccessNotification } from '@verifime/components';
import { isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { TTaskMetadata } from '../types';

export default function useLivenessPanel({ customerId }: { customerId: string }) {
  const [latestTaskMetadata, setLatestTaskMetadata] = useState<TTaskMetadata>(undefined);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    api
      .getV1taskcustomerCustomerId({
        params: { customerId },
        queries: { type: 'FACE_CHECK' },
      })
      .then((faceCheckTasks) => {
        const latestFaceCheckTaskResponse = faceCheckTasks.toSorted((a, b) =>
          isBefore(new Date(a.createdDateTime), new Date(b.createdDateTime)) ? 1 : -1,
        )?.[0];
        setLatestTaskMetadata(latestFaceCheckTaskResponse);
      })
      .catch((err) => {}); // Keep task response null once it is got error, thus ignore error handle
  }, [customerId]);

  const fetchLivenessTransaction = async () => {
    return api
      .getV2personCustomerIdlivenesstransactionlatest({
        params: { customerId },
      })
      .then((response) => {
        return response ?? { status: 'NO_RESULT' as TLivenessTransactionStatus };
      });
  };

  const generateVideoDownloadUrl = async () => {
    try {
      const response = await api.getV2personCustomerIdlivenesstransactionlatestvideo({
        params: { customerId },
      });
      return response?.videoUrl;
    } catch {
      return null;
    }
  };

  const handleFaceCheckRequest = () => {
    api
      .postV1taskcustomerCustomerId(
        { type: 'FACE_CHECK' },
        {
          params: { customerId },
        },
      )
      .then((res) => {
        setLatestTaskMetadata(res);
        enqueSuccessNotification('Request success');
      })
      .catch((err) => enqueErrorNotification(`Request failed [${err}]`));
  };

  return {
    latestTaskMetadata,
    fetchLivenessTransaction,
    generateVideoDownloadUrl,
    handleFaceCheckRequest,
  };
}
