export type TFeatureToggle = {
  isSetupCredentialNotAfterActivateTrackId: boolean;
  isSetupCredentialAllowSkip: boolean;
  isSyncUploadFaceCheckVideo: boolean;
  isShowFaceComparisonResult: boolean;
  isAllowOverrideSomeDocumentDetailsWhenItVerified: boolean;
  isShowTrafficLight: boolean;
};

export enum FeatureToggle {
  On = 'on',
  Off = 'off',
}

export function useFeatureToggle(): TFeatureToggle {
  return {
    isSetupCredentialNotAfterActivateTrackId:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SETUP_CREDENTIAL_NOT_AFTER_ACTIVATE_TRACK_ID ===
      FeatureToggle.On,
    isSetupCredentialAllowSkip:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SETUP_CREDENTIAL_ALLOW_SKIP === FeatureToggle.On,
    isSyncUploadFaceCheckVideo:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SYNC_UPLOAD_FACE_CHECK_VIDEO === FeatureToggle.On,
    isShowFaceComparisonResult:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SHOW_FACE_COMPARISON_RESULT === FeatureToggle.On,
    isAllowOverrideSomeDocumentDetailsWhenItVerified:
      process.env
        .NEXT_PUBLIC_FEATURE_TOGGLE_ALLOW_OVERRIDE_SOME_DOCUMENT_DETAILS_WHEN_IT_VERIFIED ===
      FeatureToggle.On,
    isShowTrafficLight:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SHOW_TRAFFIC_LIGHT === FeatureToggle.On,
  };
}
