import { Button, Typography } from '@mui/material';
import { ConfirmAndCancel, FlexBox, TStepContent } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';

export default function StepWrapper({
  title,
  content,
  onBack,
  onNext,
  step,
  header,
  footer,
}: {
  title?: string;
  content: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
} & TStepContent) {
  if (!title && !header) {
    throw new Error('Please given title or header');
  }

  return (
    <FlexBox
      sx={{ marginTop: tokens.spacing3xl, gap: tokens.spacing3xl, px: tokens.spacingBase }}
      direction="column"
    >
      {header ?? (
        <FlexBox sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h4">{title}</Typography>
        </FlexBox>
      )}
      <FlexBox direction="column">{content}</FlexBox>
      {footer ?? (
        <ConfirmAndCancel
          cancel={
            <>
              {step === 'first' || step === 'both' ? (
                <FlexBox></FlexBox>
              ) : (
                <Button fullWidth variant="outlined" size="large" onClick={onBack}>
                  Back
                </Button>
              )}
            </>
          }
          confirm={
            <>
              {step !== 'last' && step !== 'both' && (
                <Button fullWidth variant="contained" size="large" onClick={onNext}>
                  continue
                </Button>
              )}
            </>
          }
        />
      )}
    </FlexBox>
  );
}
