import { Box, Stack } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ReactNode } from 'react';

export default function ConfirmAndCancel({
  cancel,
  confirm,
}: Readonly<{
  cancel?: ReactNode;
  confirm: ReactNode;
}>) {
  return (
    <Stack
      sx={{
        flexDirection: ['column-reverse', 'row'],
        justifyContent: 'space-between',
        gap: tokens.spacing2lg,
        marginBottom: tokens.spacingBase,
      }}
    >
      <Box>{cancel}</Box>
      <Box>{confirm}</Box>
    </Stack>
  );
}
