import Stack from '@mui/material/Stack';
import NoDataFound from '../NoDataFound';
import CircularProgress from '@mui/material/CircularProgress';
import { TPermissionRequestDetail } from './types';
import tokens from '@verifime/design-tokens';
import PermissionRequestDetail from './PermissionRequestDetail';
import Typography from '@mui/material/Typography';

export default function ({
  isLoadingPermissionRequests: isLoadingPermissions,
  permissionRequests = [],
}: {
  isLoadingPermissionRequests: boolean;
  permissionRequests?: TPermissionRequestDetail[];
}) {
  return (
    <Stack alignItems="left">
      <Typography variant="h6">Existing Permission Requests</Typography>
      {isLoadingPermissions ? (
        <Stack justifyContent="center" alignItems="center">
          Loading Permission Requests...
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {permissionRequests.length > 0 ? (
            <Stack gap={tokens.spacingBase}>
              {permissionRequests.map((permissionRequest, i) => (
                <PermissionRequestDetail
                  key={`${permissionRequest.organisationName}-${i}`}
                  {...permissionRequest}
                />
              ))}
            </Stack>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </Stack>
  );
}
