import { Alert, AlertProps, Box, Typography } from '@mui/material';
import { TErrors } from './utils';

export default function DisplayAlertErrors({
  errors,
  ...props
}: {
  errors: TErrors;
} & AlertProps) {
  const validErrors = errors?.filter?.(Boolean) || [];

  if (validErrors.length < 1) {
    return null;
  }

  return (
    <Box>
      {errors?.map((error, index) => (
        <Alert key={index} {...props} severity="error">
          {['string', 'boolean', 'number'].includes(typeof error?.message) ? (
            <Typography>{error?.message}</Typography>
          ) : (
            error?.message
          )}
        </Alert>
      ))}
    </Box>
  );
}
