import { FormControlProps } from '@mui/material/FormControl';
import { TValidationResults } from '@verifime/api-definition';
import { ADDRESS_PREFIX_SEPARATOR, TCountryCode, TOption, stringUtils } from '@verifime/utils';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import { FieldValues, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';

export enum ErrorType {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export enum ErrorScope {
  Field = 'Field',
  Form = 'Form',
  Global = 'Global',
}

export type TErrorType = keyof typeof ErrorType;
export type TErrorScope = keyof typeof ErrorScope;
export type TError = {
  scope?: Omit<TErrorScope, 'Field'>;
  code?: string;
  message: ReactNode;
};

export type TErrors = TError[];

export type TMethod = {
  code: string;
  label: string;
  dataCy: string;
  rules: TFormFieldsAndRules;
  options?: any[];
  helpText?: string;
  showRequiredAsterisk?: boolean;
};

export type TGroupedFormFieldsAndRules = {
  basic: TFormFieldsAndRules;
  address: TFormFieldsAndRules;
  methods?: {
    [method: string]: TMethod;
  };
  schema?: {
    [schemaName: string]: TRules;
  };
};

export type TCountryFormValidations = {
  [countryCode in TCountryCode]: TGroupedFormFieldsAndRules;
};

export type TRules = {
  [name: string]: yup.Schema;
};

export enum DisplayVariant {
  SINGLE_COLUMN = 'Single Column',
  MULTIPLE_COLUMNS = 'Multiple Columns',
}

export enum RenderType {
  Text = 'Text',
  Number = 'Number',
  Select = 'Select',
  Date = 'Date',
  DateRange = 'DateRange',
  Checkbox = 'Checkbox',
  File = 'File',
  TextEditor = 'TextEditor',
  RadioGroup = 'RadioGroup',
  ComboBox = 'ComboBox',
}

export type TRenderType = keyof typeof RenderType;

export type TFromFieldInfo = {
  label: string;
  fieldName: string;
  rules: yup.Schema;
  /**
   * Because the basic form fields are different between countries,
   * Thus add those to render basic fields into the screen.
   */
  renderType?: TRenderType; // Will be rendered into the screen according to the given type
  items?: any[]; // Only be used when the `renderType = RenderType.Select` for now
  /**
   * Used to generate customise items,
   * Only be used when the `renderType = RenderType.Select` for now.
   *
   * `renderItems` has more priority if both `items` and `renderItems` exists
   */
  renderItems?: () => any;
  dataCy?: string;
  maxDate?: Date;
  minDate?: Date;
  showRequiredAsterisk?: boolean; // To control whether show * next to the field in screen
  isNewLine?: boolean; // Rendering to a new line if it is true
  helpText?: string;
  isValueUpperCase?: boolean;
  maxAcceptedItems?: number;
  disabled?: boolean;
};
export type TFormFieldsAndRules = {
  [fieldName: string]: TFromFieldInfo;
};

/**
 * TODO:
 *  1. delete existing TFormFieldsAndRules
 *  2. rename this to TFormFieldsAndRules
 */
export type TFormFieldsAndRules_New<TFieldName = string> = {
  [rows: string]: TFromFieldInfo_New<TFieldName>[];
};
/**
 * TODO:
 *  1. delete existing TFromFieldInfo
 *  2. rename this to TFromFieldInfo
 */
export type TFromFieldInfo_New<TFieldName = string> = {
  label?: string;
  // TODO: Delete this and change `label` type to `ReactNode`
  complexLabel?: ReactNode;
  // These three fields below are perhaps paradoxical to `renderCompnent`,
  // meaning that it is possible to not need the `fieldName` and `rules`
  // when using `renderComponent, vice versa.
  // TODO: Make it more semantic with the proper definitions
  fieldName: TFieldName;
  rules?: yup.Schema;
  renderType?: TRenderType; // Will be rendered into the screen according to the given type
  // `renderComponent` has more priority which means that
  // if both `renderType` and `renderComponent` exists we render contnent of `renderComponent`
  renderComponent?: () => ReactNode;
  /**
   * A shortcut to render Select options
   *
   * Only be used when the `renderType = RenderType.Select` for now
   */
  items?: TOption[];
  /**
   * Used to generate customise items,
   * Only be used when the `renderType = RenderType.Select` for now.
   *
   * `renderItems` has more priority if both `items` and `renderItems` exists
   */
  renderItems?: () => any;
  helpText?: string;
  isValueUpperCase?: boolean; // To uppercase input value
  // TODO: Use proper types for those type specific ones.
  // Such as `maxDate` should only available for `Date` related components
  onValueChange?: (value: any) => void;
  dateRangeFieldsLabels?: TFormDateRangeLabels; // Date range has two fields, each of them needs a label as well
  maxDate?: Date; // Only be used when the `renderType = RenderType.Date`
  minDate?: Date; // Only be used when the `renderType = RenderType.Date`
  size?: FormControlProps['size'];
  margin?: FormControlProps['margin'];
  sx?: FormControlProps['sx'];
  disabled?: boolean;
  multiline?: boolean; // Only be used when the `renderType = RenderType.Text`
  startAdornment?: ReactNode;
  optionProvider?: (value: string) => Promise<any>;
};

export enum DateRangeFields {
  START = 'start',
  END = 'end',
}

export type TFormDateRangeLabels = { [name in DateRangeFields]: string };

export type TFormFieldsDefaultValues = {
  flattenFormFields: Record<string, any>;
  groupedFormFields: Record<string, any>;
};

/**
 * 
 * Stripped prefixed form fields names for request.
 * 
 * According to the agreement with the backend, and in order to make each filed unique in the form,
 * it is recommended to use `prefix + name` to generate the name of form field to simplify the use of the form.
 * Among them, `prefix = path + 'sepa'` in camel case.
 * 
 * path is the so-called hierarchy, as shows in the follow example, address is a path
 * 
 * {
 *   "dateOfBirth": "",
 *   "state": "",
 *   "address": [
 *     {
 *        "postcode": "",
 *        "addressState": "",
 *     }
 *   ],
 * }
 * 
 * we need to mention that in backend we add `List` at the end of the path, like `addressList`
 * 
 * Rules:
 * 
 * 1. Generally speaking, if all fields in the form do not need a path, then no prefix is required,
 * that is to say, the prefix is `""`, such as all fields are:
 * 
 * {
 *    "firstName": "",
 *    "familyName": "",
 *    "dateOfBirth": "",
 *    "gender": "",
 *  }
 * 
 * then the form fields should be (yes, the same as the oringial, because we no need to add prefix)
 * 
 * {
 *    "firstName": "",
 *    "familyName": "",
 *    "dateOfBirth": "",
 *    "gender": "",
 *  }
 * 
 * 2. If some fields in the form require paths, it is recommended to add prefix for all fields.
 * For fields without path, we able to use `basic` as the path, like:
 * 
 * {
 *   "dateOfBirth": "",
 *   "state": "",
 *   "address": [
 *     {
 *        "postcode": "",
 *        "addressState": "",
 *     }
 *   ],
 * }
 * 
 * the fileds names should be:
 * 
 * {
 *    "basicSepaDateOfBirth": "",
 *    "basicSepaState": "",
 *    "addressSepaPostcode": "",
 *    "addressSepaAddressState": "",
 *  }
 * 
 *     field name           =  prefix (path + sepa)    + name
 * 
 * basicSepaDateOfBirth     =     basic   + Sepa       + DateOfBirth
 * basicSepaState           =     basic   + Sepa       + State
 * addressSepaPostcode      =     address + Sepa       + Postcode
 * addressSepaAddressState  =     address + Sepa       + AddressState
 * 
 * 
 * @param formInputs Using the rules (the field name is prefix + name) generated form inputs
 * 
 * example:
 * 
 * {
 *   "basicSepaFamilyName": "",
 *   "basicSepaMiddleName": "",
 *   "basicSepaFirstName": "",
 *   "passportSepaPassportNumber": "",
 *   "passportSepaPassportCountryOfIssue": "",
 *   "driverLicenceSepaLicenceState": "",
 *   "addressSepaState": "",
 *   "addressSepaPostcode": 1234,
 *   "basicSepaDateOfBirth": "",
 *   "basicSepaGender": ""
 * }
 * 
 * 
 * @param prefixes prefixes of form fileds if any
 * 
 * example:
 * 
 * [
 *   { path: '', prefix: 'basicSepa' },
 *   { path: 'addressList', prefix: 'addressSepa' },
 *   { path: 'driverLicenceList', prefix: 'driverLicenceSepa' },
 *   { path: 'passportList', prefix: 'passportSepa' },
 *   { path: 'medicareCardList', prefix: 'medicareCardSepa' },
 * ]

 * @param dateFieldsNames Temporary hack to format date until find the elegant way to format date.
 *
 * example:
 *
 * ['dateOfBirth', 'passportExpiry']
 *
 *
 * @returns Stripped prefixed form fields
 */
export function generateRequestParams(
  formInputs: Record<string, any>,
  prefixes: { path: string; prefix: string }[] = [],
  dateFieldsNames: string[] = [],
) {
  if (prefixes?.length < 1) {
    return formInputs;
  }

  let result = {};
  for (const { path, prefix } of prefixes) {
    const fields = getFields(prefix, formInputs, dateFieldsNames);
    if (!path) {
      result = { ...result, ...fields };
    } else {
      result = { ...result, [path]: hasProperties(fields) ? { ...fields } : undefined };
    }
  }

  return result;
}

/**
 *
 * Set server responsed errors to form fields.
 *
 * We have to add the prefix (if any) to fileds to generate prefixed fields names.
 *
 *
 * @param errors Errors responsed by server
 * @param setError React-hook-form's setError to set error manually
 * @param isAddPrefix  true if we wanna to add prefix for form field
 * @param basicFieldsPrefix give prefix for all of basic fields
 */
export function showFieldsErrors<TFieldValues = Record<string, any>>(
  errors: TValidationResults,
  setError: UseFormSetError<TFieldValues>,
  {
    isAddPrefix = false,
    basicFieldsPrefix = '',
  }: {
    isAddPrefix?: boolean;
    basicFieldsPrefix?: string;
  } = { isAddPrefix: false, basicFieldsPrefix: '' },
) {
  if (errors?.length < 1) {
    return;
  }

  for (const { name, path, message, resultScope } of errors) {
    if (resultScope !== ErrorScope.Field) {
      continue;
    }

    let prefix = '';
    let fieldName = name;
    if (isAddPrefix) {
      prefix = path?.split('.')[0] || basicFieldsPrefix;
      if (prefix.includes('List')) {
        prefix = prefix.replace('List', ADDRESS_PREFIX_SEPARATOR);
      }
      // To ensure Sepa added into prefix
      if (!prefix.includes(ADDRESS_PREFIX_SEPARATOR)) {
        prefix += ADDRESS_PREFIX_SEPARATOR;
      }
      fieldName = prefix + name?.slice(0, 1).toUpperCase() + name?.slice(1);
    }

    if (fieldName) {
      setError(fieldName as any, { message, type: 'server' });
    }
  }
}

export type TFormFields = {
  flattenFormFields: Record<string, any>;
  groupedFormFields: Record<string, any>;
};

export function generateFormFieldsByAPIData(
  formDataFromAPI: Record<string, any>,
  prefixes: { path: string; prefix: string }[] = [],
): TFormFieldsDefaultValues {
  const noPath = '';
  const groupedFields = new Map<string, Record<string, any> | Record<string, any>[]>();

  for (const [k, v] of Object.entries(formDataFromAPI)) {
    if (v && typeof v === 'object') {
      for (const [name, value] of Object.entries(v)) {
        if (value == null) {
          v[name] = '';
        }
      }

      groupedFields.set(k, v);
    } else {
      const fields: Record<string, any> = groupedFields.get(noPath) || {};
      fields[k] = v || '';
      groupedFields.set(noPath, fields);
    }
  }

  if (prefixes?.length < 1) {
    return {
      flattenFormFields: groupedFields.get(noPath) as any,
      groupedFormFields: { [noPath]: groupedFields.get(noPath) },
    };
  }

  let prefixedFields = {};
  let groupedPrefixedFields = {};

  for (const { path, prefix } of prefixes) {
    const fields = groupedFields.get(path);
    if (fields) {
      if (Array.isArray(fields)) {
        for (const pathFields of fields) {
          prefixedFields = { ...prefixedFields, ...generatePrefixedFields(pathFields, prefix) };
          groupedPrefixedFields = {
            ...groupedPrefixedFields,
            ...{ [path.replace('List', '')]: generatePrefixedFields(pathFields, prefix) },
          };
        }
      } else {
        prefixedFields = { ...prefixedFields, ...generatePrefixedFields(fields, prefix) };
        groupedPrefixedFields = {
          ...groupedPrefixedFields,
          ...{ [path]: generatePrefixedFields(fields, prefix) },
        };
      }
    }
  }

  return { flattenFormFields: prefixedFields, groupedFormFields: groupedPrefixedFields };
}

function getFields(
  prefix: string,
  formInputs: Record<string, any>,
  dateFieldsNames: string[] = [],
) {
  const filteredFields: Record<string, any> = {};
  for (const [k, v] of Object.entries(formInputs)) {
    if (k.startsWith(prefix)) {
      const fieldName = getFieldName(prefix, k);
      if (fieldName) {
        // Temporary hack to format date until find the elegant way to format date.
        if (dateFieldsNames.includes(fieldName)) {
          if (v) {
            filteredFields[fieldName] = format(new Date(v), 'yyyy-MM-dd');
          }
        } else {
          filteredFields[fieldName] = v;
        }
      }
    }
  }
  return filteredFields;
}

export function generateNonFieldErrors(validationResults: TValidationResults): TErrors | null {
  if (!validationResults) {
    return null;
  }
  const nonFieldErrors: TErrors = [];
  const nonFieldScopes = Object.keys(ErrorScope).filter((scope) => scope !== ErrorScope.Field);

  for (const validationResult of validationResults) {
    if (nonFieldScopes.includes(validationResult?.resultScope)) {
      nonFieldErrors.push({
        scope: validationResult.resultScope,
        message: validationResult.message,
      });
    }
  }
  return nonFieldErrors;
}

export function generateErrors(validationResults: TValidationResults): TErrors {
  if (!validationResults) {
    return [];
  }
  const errors: TErrors = [];
  for (const validationResult of validationResults) {
    errors.push({
      scope: validationResult.resultScope,
      message: validationResult.message,
    });
  }
  return errors;
}

export function getFieldsRules(
  formFieldsAndRules?: TFormFieldsAndRules | TGroupedFormFieldsAndRules,
  prefix?: keyof typeof formFieldsAndRules,
): TRules {
  let filtered = formFieldsAndRules as TFormFieldsAndRules;
  if (prefix) {
    filtered = formFieldsAndRules[prefix] as TFormFieldsAndRules;
  }

  if (!filtered) {
    return {};
  }
  const fieldsRules = {} as TRules;
  for (const { fieldName, rules } of Object.values(filtered)) {
    fieldsRules[fieldName] = rules;
  }

  return fieldsRules;
}

/**
 *
 * TODO:
 *  1. delete the existing getFieldsRules
 *  2. rename getFieldsRules_New to getFieldsRules
 */
export function getFieldsRules_New<TFieldName = string>(
  formFieldsAndRules?: TFormFieldsAndRules_New<TFieldName>,
  prefix?: keyof typeof formFieldsAndRules,
): TRules {
  // TODO: processing prefix situation ?

  if (Object.keys(formFieldsAndRules || {}).length < 1) {
    return {};
  }

  const fieldsRules = {} as TRules;
  for (const [_, fieldsInfo] of Object.entries(formFieldsAndRules)) {
    fieldsInfo.forEach((fieldInfo) => {
      if (fieldInfo.renderType === 'DateRange') {
        for (const [_, value] of Object.entries(fieldInfo.dateRangeFieldsLabels)) {
          fieldsRules[stringUtils.generateCamelCaseString(String(fieldInfo.fieldName), value)] =
            fieldInfo.rules;
        }
      } else {
        fieldsRules[String(fieldInfo.fieldName)] = fieldInfo.rules;
      }
    });
  }
  return fieldsRules;
}

/**
 *
 * TODO: Rename it (delete _New suffix) once change to use new formfieldsAndRules generation
 */
export function getFieldsNamesFromFieldsAndRules_New(
  formFieldsAndRules?: TFormFieldsAndRules_New,
): string[] {
  const fieldsNameArray: string[] = [];
  if (!formFieldsAndRules) {
    return fieldsNameArray;
  }

  for (const [_, rowItems] of Object.entries(formFieldsAndRules)) {
    rowItems.forEach((item) => fieldsNameArray.push(item.fieldName));
  }
  return fieldsNameArray;
}

export function getFieldInfoByFieldName(
  fieldsAndRules: TFormFieldsAndRules_New,
  fieldName: string,
): TFromFieldInfo_New {
  if (Object.keys(fieldsAndRules || {}).length < 1) {
    return {} as TFromFieldInfo_New;
  }

  if (!fieldName) {
    return {} as TFromFieldInfo_New;
  }

  for (const [_, fieldsInfo] of Object.entries(fieldsAndRules)) {
    const foundFieldInfo = fieldsInfo.find((fieldInfo) => {
      if (fieldInfo.renderType === 'DateRange') {
        let foundDateRangeField = false;
        for (const [, labelValue] of Object.entries(fieldInfo.dateRangeFieldsLabels)) {
          if (stringUtils.generateCamelCaseString(fieldInfo.fieldName, labelValue) === fieldName) {
            foundDateRangeField = true;
            break;
          }
        }
        return foundDateRangeField;
      }
      return fieldInfo.fieldName === fieldName;
    });
    if (foundFieldInfo) {
      return foundFieldInfo;
    }
  }

  return {} as TFromFieldInfo_New;
}

export function resetFields(
  setValue: UseFormSetValue<FieldValues>,
  fieldsNames: string[],
  options: { excludes: string[] } = { excludes: [] },
) {
  if (fieldsNames.length < 1) {
    return;
  }
  for (const fieldName of fieldsNames) {
    if (!options?.excludes?.includes(fieldName)) {
      setValue(fieldName, '');
    }
  }
}

export function getFieldsNamesFromFieldsAndRules(fieldsAndRules: TFormFieldsAndRules): string[] {
  const fieldsNameArray: string[] = [];
  if (!fieldsAndRules) {
    return fieldsNameArray;
  }
  for (const [_, fieldInfo] of Object.entries(fieldsAndRules)) {
    fieldsNameArray.push(fieldInfo.fieldName);
  }
  return fieldsNameArray;
}

export function getFieldsPrefixFromFieldsAndRules(fieldsAndRules: TFormFieldsAndRules): string {
  const fieldsNames = getFieldsNamesFromFieldsAndRules(fieldsAndRules);
  const prefix = fieldsNames[0].split(ADDRESS_PREFIX_SEPARATOR)[0];
  return prefix ?? '';
}

function getFieldName(prefix: string, str: string) {
  const captilizedStr = str?.split(prefix)[1];
  if (!captilizedStr) {
    return null;
  }
  return captilizedStr.slice(0, 1).toLowerCase() + captilizedStr.slice(1);
}

function hasProperties(obj: Record<string, any>) {
  return Object.keys(obj).length > 0;
}

function generatePrefixedFields(fields: Record<string, any>, prefix: string) {
  const result: Record<string, any> = {};
  for (const [name, value] of Object.entries(fields || {})) {
    const fieldName = prefix + name.slice(0, 1).toUpperCase() + name.slice(1);
    result[fieldName] = value;
  }
  return result;
}

export const formFieldLabel = (label: string | ReactNode, isRequired: boolean) => {
  if (typeof label === 'string') {
    return `${label} ${isRequired ? '*' : ''}`;
  }
  return label;
};

/**
 *
 * Mark fields of `formFieldsAndRules` readonly once the fields name included by `readonlyFieldsName`
 */
export function markFieldsReadonly(
  formFieldsAndRules: TFormFieldsAndRules_New,
  readonlyFieldsName: string[] = [],
) {
  if (readonlyFieldsName?.length < 1) {
    return;
  }

  Object.values(formFieldsAndRules)
    .flat()
    .forEach((fieldInfo) => {
      if (readonlyFieldsName.includes(fieldInfo.fieldName)) {
        fieldInfo.disabled = true;
      }
    });
}
