import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { api, TValidationResults } from '@verifime/api-definition';
import {
  ConfirmAndCancel,
  DisplayAlertErrors,
  GenerateFormFields_New,
  getFieldsRules_New,
  RenderType,
  StepWrapper,
  TFormFieldsAndRules_New,
  TStepContent,
  useConfirm,
  useCustomForm,
} from '@verifime/components';
import tokens from '@verifime/design-tokens';
import {
  DocumentType,
  EntityStatus,
  stringUtils,
  TDocument,
  validationRules,
} from '@verifime/utils';
import { EntityDocumentUploader, TIdentityDocumentsUploadConfig } from '@verifime/views';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';
import { buildDiffMessages, diff, TDiffResult, updatePersonAccordingDiffResult } from './diff';
import {
  OcrEditor,
  SUPPORT_OCR_DOCUMENT_TYPES,
  TOcrData,
  TSupportedOcrDocumentType,
} from './OcrEditor';

type DocumentInstructionsType = {
  [DocumentType.Passport]: string;
  [DocumentType.DriverLicence]: {
    main: string;
    note: string;
  };
  [DocumentType.MedicareCard]: string;
  [DocumentType.OtherIdentityDocument]: string;
};

const DOCUMENT_INSTRUCTIONS: DocumentInstructionsType = {
  [DocumentType.Passport]:
    'Upload a photo of the first page of your passport showing your details clearly.',
  [DocumentType.DriverLicence]: {
    main: "Upload photos of the FRONT AND BACK of your driver's licence. Make sure your photo and card number are clearly visible.",
    note: 'If you are using the Service NSW app, you may need to scroll down to be able to see your card number. Take two screenshots in this case to capture both your photo and card number.',
  },
  [DocumentType.MedicareCard]:
    'Upload a photo of the FRONT of your Medicare Card showing your details clearly.',
  [DocumentType.OtherIdentityDocument]:
    'eg. a government-issued national ID card. Upload a photo of the document showing your details clearly.',
} as const;

type TEntityDocumentsAndOcrProps = TStepContent & {
  verificationMethodStep: number;
  onSuccess?: () => void;
  config?: TIdentityDocumentsUploadConfig;
};

const DocumentInstructions = ({ documentType }: { documentType: DocumentType }) => {
  if (!(documentType in DOCUMENT_INSTRUCTIONS)) {
    return null;
  }

  if (documentType === DocumentType.DriverLicence) {
    return (
      <>
        <Typography variant="body1">
          {DOCUMENT_INSTRUCTIONS[DocumentType.DriverLicence].main}
        </Typography>
        <Typography variant="body1">
          <b>Note:</b> {DOCUMENT_INSTRUCTIONS[DocumentType.DriverLicence].note}
        </Typography>
      </>
    );
  }

  const instructions = DOCUMENT_INSTRUCTIONS[documentType as keyof DocumentInstructionsType];
  return typeof instructions === 'string' ? (
    <Typography variant="body1">{instructions}</Typography>
  ) : null;
};

const Header = () => (
  <Box>
    <Typography variant="h5">Add verification Documents</Typography>
    <Typography variant="subtitle1">Upload valid ID(s) to verify your identity</Typography>
  </Box>
);

const DRIVERS_LICENCE_NOT_ENOUGH_MESSAGE = (
  <Stack gap={tokens.spacingXs}>
    <Typography variant="h6">
      Care - both front and back of your driver&apos;s licence is required
    </Typography>
    <Typography>
      Please ensure your file contains <b>both</b> the <b>front and back</b> of your driver&apos;s
      licence or upload a second file containing the back of your licence
    </Typography>
  </Stack>
);

export const EntityDocumentsAndOcr = ({
  onBack,
  onNext,
  step,
  numSteps,
  verificationMethodStep,
  memoizedObj,
  setMemoizedObj,
  onSuccess,
  config: { documentTypeChoices, documentCount },
}: TEntityDocumentsAndOcrProps) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentType | null>(null);
  const [continueState, setContinueState] = useState<
    'NONE' | 'NEED_CONFIRM' | 'FINISH_UPLOAD' | 'FINISH_OCR'
  >('NONE');
  const [isRetriving, setIsRetriving] = useState(false);
  const formDataRef = useRef<TOcrData | null>(null);
  const [saveOcrErrors, setSaveOcrErrors] = useState([]);
  const [noEnoughFilesUploadedMessage, setNoEnoughFilesUploadedMessage] =
    useState<ReactNode>(undefined);
  const [diffResult, setDiffResult] = useState<TDiffResult>({} as TDiffResult);
  const confirm = useConfirm();

  const supportedDocumentTypes = useMemo(() => {
    // For subsequent steps, filter out previously selected document types
    // Get all verification methods from previous steps
    let previousStepsMethods = memoizedObj.verificationMethods
      ? [...memoizedObj.verificationMethods].slice(0, verificationMethodStep - 1).filter(Boolean)
      : [];

    // For the first step, show all available document types
    if (verificationMethodStep === 1) {
      previousStepsMethods = [];
    }

    // Use selectedDocumentTypes to filter out previously selected document types
    const filteredTypes = getFilteredDocumentTypes({
      selectedDocumentTypes: previousStepsMethods,
      documentTypeChoices,
    });
    return filteredTypes;
  }, [memoizedObj.verificationMethods, verificationMethodStep, documentTypeChoices]);

  const fieldName = `verificationMethod${verificationMethodStep}`;

  const formFieldsAndRules: TFormFieldsAndRules_New = {
    row1: [
      {
        label: 'Select Document Type',
        fieldName,
        renderType: RenderType.Select,
        rules: validationRules.REQUIRED_STRING_RULES,
        items: supportedDocumentTypes,
      },
    ],
  };

  const {
    control,
    formState: { errors },
    setValue,
  } = useCustomForm({ schema: getFieldsRules_New(formFieldsAndRules) });

  const initializeVerificationMethods = useCallback(() => {
    const defaultDocumentTypes = getDefaultDocumentTypes(supportedDocumentTypes, documentCount);

    setMemoizedObj((prev) => ({
      ...prev,
      verificationMethods: defaultDocumentTypes,
    }));
  }, [setMemoizedObj, supportedDocumentTypes]);

  useEffect(() => {
    if (!memoizedObj.verificationMethods) {
      setIsRetriving(true);

      api
        .getV1personIdverificationDocuments({ params: { id: memoizedObj.customer.id } })
        .then((docs) => {
          // Get unique document types from API response
          const uniqueDocTypes = [...new Set(docs.map((doc) => doc.docType))];

          // Reorder document types to match documentTypeChoices order,
          // then filter out document types that are not available
          const existingDocumentTypes = documentTypeChoices
            .map((choice) => choice.code)
            .filter((code) => uniqueDocTypes.includes(code));

          if (existingDocumentTypes.length > 0) {
            // Truncate existingDocumentTypes to match documentCount if needed
            const truncatedTypes = existingDocumentTypes.slice(0, documentCount);
            setMemoizedObj((prev) => ({
              ...prev,
              verificationMethods: truncatedTypes,
              retrievedVerificationMethods: existingDocumentTypes,
            }));
          } else {
            const defaultDocumentTypes = getDefaultDocumentTypes(
              supportedDocumentTypes,
              documentCount,
            );
            setMemoizedObj((prev) => ({
              ...prev,
              verificationMethods: defaultDocumentTypes,
              retrievedVerificationMethods: existingDocumentTypes,
            }));
          }
        })
        .finally(() => {
          setIsRetriving(false);
        });
    }
  }, [
    initializeVerificationMethods,
    memoizedObj.customer.id,
    memoizedObj.verificationMethods,
    setMemoizedObj,
    supportedDocumentTypes,
  ]);

  useEffect(() => {
    if (!memoizedObj.verificationMethods?.length) {
      initializeVerificationMethods();
      return;
    }

    // Get the document type for the current step
    const currentDoccumentType = memoizedObj.verificationMethods[verificationMethodStep - 1];

    // If currentDocumentType is not available, set a default based on supported types
    if (!currentDoccumentType) {
      const filteredTypes =
        verificationMethodStep === 1
          ? supportedDocumentTypes
          : getFilteredDocumentTypes({
              // Pass all previously selected document types as an array
              selectedDocumentTypes: memoizedObj.verificationMethods
                .slice(0, verificationMethodStep - 1)
                .filter(Boolean),
              documentTypeChoices: documentTypeChoices,
            });
      const defaultDocumentType = filteredTypes[0]?.code as DocumentType;

      // Update memoizedObj with the default doc type
      setMemoizedObj((prev) => {
        // Create a copy of the current verification methods or initialize an empty array
        const updatedMethods = [...(prev.verificationMethods || [])];

        // Set the document type for the current step
        updatedMethods[verificationMethodStep - 1] = defaultDocumentType;

        return {
          ...prev,
          verificationMethods: updatedMethods,
        };
      });

      setSelectedDocumentType(defaultDocumentType);
      setValue(fieldName, defaultDocumentType);
    } else {
      setSelectedDocumentType(currentDoccumentType as DocumentType);
      setValue(fieldName, currentDoccumentType);
    }
  }, [
    fieldName,
    initializeVerificationMethods,
    memoizedObj.verificationMethods,
    setValue,
    verificationMethodStep,
    supportedDocumentTypes,
    setMemoizedObj,
    documentTypeChoices,
  ]);

  useEffect(
    function resetDiffResult() {
      setDiffResult({} as TDiffResult);
    },
    [selectedDocumentType],
  );

  const handleDocumentTypeChanges = (DocumentType: DocumentType) => {
    // Get current verification methods
    const currentMethods = [...(memoizedObj.verificationMethods ?? [])];

    // Update the current step's document type
    currentMethods[verificationMethodStep - 1] = DocumentType;

    // Ensure we don't have duplicate document types when possible
    // If the selected type is already used in another step, try to swap them
    for (let i = 0; i < currentMethods.length; i++) {
      // Skip the current step
      if (i === verificationMethodStep - 1) continue;

      // If we found a duplicate, try to replace it with a different type
      if (currentMethods[i] === DocumentType) {
        // Find an unused document type
        const unusedType = supportedDocumentTypes.find(
          (type) => !currentMethods.includes(type.code as DocumentType),
        );

        if (unusedType) {
          // Replace the duplicate with an unused type
          currentMethods[i] = unusedType.code as DocumentType;
        }
        // If no unused type is available, we keep the duplicate
      }
    }

    setMemoizedObj((prev) => ({
      ...prev,
      verificationMethods: currentMethods,
    }));

    setSelectedDocumentType(DocumentType);
  };

  const handleOcrFormChange = useCallback(
    (isValid: boolean, data: TOcrData) => {
      formDataRef.current = data;
      setSaveOcrErrors([]);
      setDiffResult(diff(memoizedObj.customer, data));
      if (isValid) {
        setContinueState('FINISH_OCR');
      } else {
        setContinueState('FINISH_UPLOAD');
      }
    },
    [memoizedObj.customer],
  );

  const handleUploadLimitReached = useCallback(
    ({ uploadedFiles }: { uploadedFiles: any[] }) => {
      if (uploadedFiles.length < 1) {
        formDataRef.current = null;
        setContinueState('NONE');
        setNoEnoughFilesUploadedMessage(undefined);
        return;
      }
      if (selectedDocumentType === DocumentType.DriverLicence) {
        if (uploadedFiles.length < 2) {
          formDataRef.current = null;
          setContinueState('NEED_CONFIRM');
          setNoEnoughFilesUploadedMessage(DRIVERS_LICENCE_NOT_ENOUGH_MESSAGE);
          return;
        }
      }

      setNoEnoughFilesUploadedMessage(undefined);

      // We don't support ocr for OtherIdentityDocument right now,
      // thus allow `continue` directly
      if (selectedDocumentType === DocumentType.OtherIdentityDocument) {
        setContinueState('FINISH_OCR');
      } else {
        // Handle race conditions update
        setContinueState((prev) => {
          if (formDataRef.current && prev === 'FINISH_OCR') {
            return prev;
          }
          return 'FINISH_UPLOAD';
        });
      }
    },
    [selectedDocumentType],
  );

  const handleContinue = async () => {
    if (noEnoughFilesUploadedMessage) {
      confirm({
        content: (
          <Typography>
            Please confirm you have provided <b>both</b> the <b>front and back</b> of your driver’s
            licence.
          </Typography>
        ),
        confirmationText: 'I have uploaded two sides',
      }).then(() => {
        setContinueState('FINISH_UPLOAD');
        setNoEnoughFilesUploadedMessage(undefined);
      });
      return;
    }

    const currentCustomer = { ...memoizedObj.customer };
    if (verificationMethodStep === documentCount) {
      let verificationMethods = memoizedObj.verificationMethods as TDocument['code'][];

      const nonUsedDocumentTypes = documentTypeChoices.filter(
        (documentType) => !verificationMethods.includes(documentType.code),
      );

      // Delete unused document data from current customer
      nonUsedDocumentTypes.forEach((documentType) => {
        const documentFieldName = stringUtils.lowerCaseFirstLetter(documentType.code);
        delete currentCustomer[documentFieldName];
      });

      // Ask to delete unused document (keep selected ones)
      api.putV1personIdverificationDocuments(verificationMethods, {
        params: { id: memoizedObj.customer.id },
      });
    }

    // Customers only needs to upload OtherIdentityDocument, Admin will do the rest.
    if (selectedDocumentType === DocumentType.OtherIdentityDocument) {
      onSuccess?.();
      onNext();
      return;
    }

    const documentFieldName = stringUtils.lowerCaseFirstLetter(selectedDocumentType);
    let documentData = {} as TOcrData;
    // TODO: Further code clean
    if (selectedDocumentType === DocumentType.Passport) {
      documentData = formDataRef.current.passport;
      documentData.passportStatus = 'InProgress';
      documentData.passportExpiry = format(
        new Date(documentData.passportExpiry as Date),
        'yyyy-MM-dd',
      );
    }
    if (selectedDocumentType === DocumentType.DriverLicence) {
      documentData = formDataRef.current.driverLicence;
      documentData.licenceStatus = 'InProgress';
      documentData.licenceExpiry = format(
        new Date(documentData.licenceExpiry as Date),
        'yyyy-MM-dd',
      );
    }
    if (selectedDocumentType === DocumentType.MedicareCard) {
      documentData = formDataRef.current.medicareCard;
      documentData.medicareStatus = 'InProgress';
      documentData.medicareCardExpiry = format(
        new Date(documentData.medicareCardExpiry as Date),
        'yyyy-MM-dd',
      );
    }
    const person = { ...currentCustomer, [documentFieldName]: documentData };

    updatePersonAccordingDiffResult(person, diffResult);

    api
      .putV2personId(
        { entityStatus: EntityStatus.Draft, person },
        { params: { id: memoizedObj.customer.id } },
      )
      .then((res) => {
        setMemoizedObj((prev) => ({
          ...prev,
          customer: res.customer,
        }));
        onSuccess?.();
        onNext();
      })
      .catch((error) => {
        console.error('error:', error);
        setContinueState('FINISH_UPLOAD');
        const validationResults = error['validationResults'] as TValidationResults;
        if (validationResults?.length > 0) {
          setSaveOcrErrors(validationResults.map((r) => `${r.name ?? ''} ${r.message}`));
          return;
        }
        setSaveOcrErrors([error?.message || 'Unknown error.']);
      });
  };

  /**
   * TODO: Needs to handle the situation that the driver's licence has more than one uploaded files,
   * and only delete selected document data from current curstomer when all files are removed
   */
  const handleFileRemove = () => {
    setDiffResult({} as TDiffResult);
    setSaveOcrErrors([]);
    const toBeUpdatedMeoizedObj = { ...memoizedObj };
    const documentFieldName = stringUtils.lowerCaseFirstLetter(selectedDocumentType);
    // No such document data in the customer, no need to call api to delete the data
    if (!(documentFieldName in toBeUpdatedMeoizedObj.customer)) {
      return;
    }
    delete toBeUpdatedMeoizedObj.customer[documentFieldName];
    api
      .putV2personId(
        { entityStatus: EntityStatus.Draft, person: toBeUpdatedMeoizedObj.customer },
        { params: { id: memoizedObj.customer.id } },
      )
      .then((res) => {
        setMemoizedObj((prev) => ({
          ...prev,
          customer: res.customer,
        }));
      })
      .catch((error) => console.error(error));
  };

  const disabledContinue = !['FINISH_OCR', 'NEED_CONFIRM'].includes(continueState);
  const diffMessages = buildDiffMessages(diffResult);

  return (
    <StepWrapper
      header={<Header />}
      content={
        <Stack gap={tokens.spacingLg}>
          {selectedDocumentType && <DocumentInstructions documentType={selectedDocumentType} />}

          {isRetriving ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            selectedDocumentType && (
              <Stack key={selectedDocumentType} gap={tokens.spacingBase}>
                <GenerateFormFields_New
                  formFieldsAndRules={formFieldsAndRules}
                  control={control}
                  errors={errors}
                  fieldsOperations={{
                    [fieldName]: {
                      onOptionChange: handleDocumentTypeChanges,
                      initValue: selectedDocumentType,
                    },
                  }}
                />
                {noEnoughFilesUploadedMessage && (
                  <DisplayAlertErrors errors={[{ message: noEnoughFilesUploadedMessage }]} />
                )}
                <EntityDocumentUploader
                  multiple={selectedDocumentType === DocumentType.DriverLicence}
                  maxFileCount={selectedDocumentType === DocumentType.DriverLicence ? 2 : 1}
                  entityId={memoizedObj.customer.id}
                  documentType={selectedDocumentType}
                  onLimitReached={handleUploadLimitReached}
                  onFileRemove={handleFileRemove}
                />
                {diffMessages.length > 0 && (
                  <DisplayAlertErrors
                    errors={diffMessages.map((diffMessage) => ({ message: diffMessage }))}
                  />
                )}

                {(continueState === 'FINISH_UPLOAD' || continueState === 'FINISH_OCR') &&
                  SUPPORT_OCR_DOCUMENT_TYPES.includes(
                    selectedDocumentType as TSupportedOcrDocumentType,
                  ) && (
                    <OcrEditor
                      key={`${selectedDocumentType}-${memoizedObj.customer.id}`}
                      customer={memoizedObj.customer}
                      documentType={selectedDocumentType as TSupportedOcrDocumentType}
                      onFormChange={handleOcrFormChange}
                    />
                  )}
                {saveOcrErrors.length > 0 && (
                  <DisplayAlertErrors errors={saveOcrErrors.map((error) => ({ message: error }))} />
                )}
              </Stack>
            )
          )}
        </Stack>
      }
      footer={
        <ConfirmAndCancel
          cancel={
            <Button fullWidth variant="outlined" size="large" onClick={onBack}>
              Back
            </Button>
          }
          confirm={
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={disabledContinue}
              onClick={handleContinue}
            >
              Continue
            </Button>
          }
        />
      }
      step={step}
      numSteps={numSteps}
      memoizedObj={memoizedObj}
      setMemoizedObj={setMemoizedObj}
    />
  );
};

function getFilteredDocumentTypes({
  selectedDocumentTypes,
  documentTypeChoices,
}: {
  selectedDocumentTypes?: DocumentType[];
  documentTypeChoices?: TIdentityDocumentsUploadConfig['documentTypeChoices'];
}): TDocument[] {
  if (!documentTypeChoices || documentTypeChoices.length === 0) {
    throw new Error('Document type choices must be provided');
  }

  // Convert document type choices to TDocument array
  let documentTypes = documentTypeChoices.map(({ code, label }) => ({
    code,
    label,
  }));

  // If we have an array of document types to exclude, filter them all out
  if (selectedDocumentTypes && selectedDocumentTypes.length > 0) {
    return documentTypes.filter(
      (item) => !selectedDocumentTypes.includes(item.code as DocumentType),
    );
  }
  return documentTypes;
}

function getDefaultDocumentTypes(
  supportedDocumentTypes: TDocument[],
  documentCount: number,
): DocumentType[] {
  // Return an array of document types with length matching config.documentCount
  if (supportedDocumentTypes.length === 0) {
    return [];
  }

  // When documentCount is 1, only return the first document type
  if (documentCount === 1) {
    return [supportedDocumentTypes[0].code as DocumentType];
  }

  // Always use the first document type for the first step
  const defaultTypes: DocumentType[] = [supportedDocumentTypes[0].code as DocumentType];

  // For each additional step, try to find a unique document type
  // If not enough unique types are available, reuse existing types
  const usedTypes = new Set([defaultTypes[0]]);

  // Add remaining document types, trying to avoid duplicates when possible
  for (let i = 1; i < documentCount && i < supportedDocumentTypes.length; i++) {
    // Find a document type that hasn't been used yet
    const availableType = supportedDocumentTypes.find(
      (type) => !usedTypes.has(type.code as DocumentType),
    );

    if (availableType) {
      defaultTypes.push(availableType.code as DocumentType);
      usedTypes.add(availableType.code as DocumentType);
    } else {
      // If all types have been used, start reusing from the beginning
      defaultTypes.push(
        supportedDocumentTypes[i % supportedDocumentTypes.length].code as DocumentType,
      );
    }

    return defaultTypes;
  }
}
