import { StepsWizard, TStep, TStepHeader } from '@verifime/components';
import { TIndividual } from '@verifime/utils';
import { EntityDocumentsAndOcr } from './EntityDocumentsAndOcr';
import { TIdentityDocumentsUploadConfig } from './types';

type TStepOperations = {
  onSuccess?: () => void;
  config: TIdentityDocumentsUploadConfig;
};

const generateStep = ({
  onSuccess,
  config,
  stepNumber,
}: TStepOperations & { stepNumber: number }): TStep => {
  return {
    content: (stepInfo) => {
      return (
        <EntityDocumentsAndOcr
          key={`entityDocumentsAndOcr${stepNumber}`}
          verificationMethodStep={stepNumber}
          onSuccess={onSuccess}
          config={config}
          {...stepInfo}
        />
      );
    },
  };
};

export const getIdentityDocumentsUploadFullSteps = ({ onSuccess, config }: TStepOperations) => {
  if (!config) {
    throw new Error('Configuration is required for IdentityDocumentsUpload');
  }

  if (
    !config.documentTypeChoices ||
    !Array.isArray(config.documentTypeChoices) ||
    config.documentTypeChoices.length === 0
  ) {
    console.error('documentTypeChoices is required and must be a non-empty array');
    return [];
  }

  if (!config.documentCount) {
    console.error('documentCount is required and must be a number greater than 0');
    return [];
  }

  if (config.documentCount > config.documentTypeChoices.length) {
    console.error('documentCount must be less than or equal to documentTypeChoices length');
    return [];
  }

  const steps: TStep[] = [];
  for (let i = 1; i <= config.documentCount; i++) {
    const isLastStep = i === config.documentCount;
    steps.push(
      generateStep({
        onSuccess: isLastStep ? onSuccess : undefined,
        config,
        stepNumber: i,
      }),
    );
  }
  return steps;
};

export const IdentityDocuemntsUpload = ({
  customer,
  header,
  onSuccess,
  config,
}: {
  customer: TIndividual;
  header?: TStepHeader;
} & TStepOperations) => {
  const uploadSteps = getIdentityDocumentsUploadFullSteps({
    onSuccess,
    config,
  });
  return (
    <StepsWizard
      prepare={({ setMemoizedObj }) => {
        setMemoizedObj((memoizedObj) => ({ ...memoizedObj, customer }));
      }}
      isShowStepConnector={uploadSteps.length > 1}
      steps={uploadSteps}
      header={header}
    />
  );
};
