import { Box } from '@mui/material';
import { api, apiSchemas } from '@verifime/api-definition';
import {
  DisplayAlertErrors,
  FileType,
  FileUploader,
  TFileUploaderProps,
  TInitialFile,
  TUploadFile,
} from '@verifime/components';
import { DocumentType } from '@verifime/utils';
import { FC, useEffect, useState } from 'react';
import { z } from 'zod';

const uploadFileByPresignUrl = (
  presignUploadUrl: string,
  file: File,
  onProgress?: (progress: number) => void,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Add progress event listener
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        onProgress?.(percentComplete);
      }
    });

    // Add load event listener
    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve();
      } else {
        console.error(`Upload failed with status ${xhr.status}`);
        reject(new Error(`Upload failed with status ${xhr.status}`));
      }
    });

    // Add error event listener
    xhr.addEventListener('error', (error) => {
      console.error('Upload error:', error);
      reject(new Error('Upload failed'));
    });

    // Open and send the request
    xhr.open('PUT', presignUploadUrl);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};

export const entityDocumentApi = {
  upload: async (
    file: File,
    onProgress: (progress: number) => void,
    entityId: TEntityDocumentUploader['entityId'],
    documentType: TEntityDocumentUploader['documentType'],
  ): Promise<{ url: string; id: string }> => {
    try {
      const presingUrlResponse = await api.postV1filepresign({
        entityId,
        documentType,
        filenameList: [file.name],
      });
      const presignUrl = presingUrlResponse.presignUrlList?.[0] as z.infer<
        typeof apiSchemas.PresignUrl
      >;
      if (!presignUrl?.url) {
        console.error('No presign upload url found');
        throw new Error('Upload failed');
      }
      await uploadFileByPresignUrl(presignUrl.url, file, onProgress);

      /**
       * Create new URL for the file
       *
       * To preview the uploaded file needs an extra api call
       * `GET /v1/file/{entityId}/{docType}/{fileId}/presignDownload`,
       * but we know the file is uploaded successfully,
       * thus we can create the file url by file directly to preview the uploaded file to avoid extra api call.
       */
      const fileUrl = URL.createObjectURL(file);
      return { url: fileUrl, id: presignUrl.fileId };
    } catch (error) {
      console.error('Upload error:', error);
      throw new Error('Upload failed');
    }
  },

  delete: async (
    file: TUploadFile,
    entityId: TEntityDocumentUploader['entityId'],
    documentType: TEntityDocumentUploader['documentType'],
  ): Promise<void> => {
    try {
      await api.deleteV1fileEntityIdDocTypeFileId(undefined, {
        params: { entityId, fileId: file.id, docType: documentType },
      });
    } catch (error) {
      console.error('Delete error:', error);
    }
  },
  fetchInitialFiles: async ({
    entityId,
    documentType,
  }: {
    entityId: TEntityDocumentUploader['entityId'];
    documentType: TEntityDocumentUploader['documentType'];
  }): Promise<TInitialFile[]> => {
    try {
      // Get list of files info first
      const listFiles = await api.getV1fileEntityIdDocType({
        params: { entityId, docType: documentType },
      });

      if (listFiles.itemList?.length < 1) {
        return;
      }

      // Get all presign download
      const presignUrls = await Promise.all(
        listFiles.itemList.map((item) =>
          api.getV1fileEntityIdDocTypeFileIdpresignDownload({
            params: { entityId, docType: documentType, fileId: item.fileId },
          }),
        ),
      );

      return presignUrls.map(({ url, fileId, filename }) => ({ url, id: fileId, name: filename }));
    } catch (error) {
      console.error('Error fetching initial file:', error);
      throw new Error('Fetching initial file failed');
    }
  },
};

type TEntityDocumentUploader = {
  entityId: string;
  documentType: DocumentType;
} & Omit<TFileUploaderProps, 'onUpload' | 'onDelete'>;

/**
 * Due to backend design, entity document upload needs two steps:
 * 
 * 1. Call the endpoint `POST /v1/file/presign` to get the presign upload url (let's say fileUploadUrl)
 * 2. Call `
 *  fetch(fileUploadUrl, {
      method: 'PUT',
      headers: { 'Content-Type': fileType },
      body: file,
    })
 * ` to upload the file
 * 
*/
export const EntityDocumentUploader: FC<TEntityDocumentUploader> = ({
  entityId,
  documentType,
  ...props
}) => {
  const [initialFiles, setInitialFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!documentType) {
      return;
    }
    entityDocumentApi
      .fetchInitialFiles({ entityId, documentType })
      .then(setInitialFiles)
      .catch((error) => setErrorMessage(error?.message || 'Unknown errors'));
  }, [entityId, documentType]);

  // Clear error message after 5 seconds
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  return (
    <Box>
      {errorMessage && <DisplayAlertErrors errors={[{ message: errorMessage }]} />}
      <FileUploader
        hideUploaderAfterUpload
        hideUploaderOnInitialFiles
        acceptedFileTypes={[FileType.JPEG, FileType.PNG, FileType.PDF]}
        onUpload={(file, onProgress) =>
          entityDocumentApi.upload(file, onProgress, entityId, documentType)
        }
        initialFiles={initialFiles}
        onDelete={(uploadedFile) => entityDocumentApi.delete(uploadedFile, entityId, documentType)}
        {...props}
      />
    </Box>
  );
};
