import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React from 'react';
import { TFilePreviewModalProps } from './types';

export const FilePreviewModal: React.FC<TFilePreviewModalProps> = ({
  open,
  onClose,
  fileUrl,
  isPDF,
}) => {
  if (!fileUrl) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '90vh',
          height: '90vh',
          bgcolor: 'white',
        },
      }}
    >
      <DialogTitle
        sx={{
          p: tokens.spacingBase,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            '&:hover': {
              bgcolor: 'grey.100',
            },
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: `calc(100% - ${tokens.spacing4xl})` }}>
        {isPDF ? (
          <iframe
            src={fileUrl}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
            }}
            title="PDF Preview"
          />
        ) : (
          <img
            src={fileUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            alt="Preview"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
