import { FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { DisplayAlertErrors } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { medicareCardFormFields } from './formFieldConfigs';
import { TMedicareCardOwners } from './utils';

export const MedicareCardOwnersInputs = ({
  medicareCardOwners,
  onOwnerSelected,
}: {
  medicareCardOwners: TMedicareCardOwners;
  onOwnerSelected: ({ id, name }: { id: string; name: string }) => void;
}) => {
  const { setValue, trigger } = useFormContext();
  const [selectedOwnerIndex, setSelectedOwnerIndex] = useState(-1);
  const [memoizedMedicareCardOwners, setMemoizedMedicareCardOwners] = useState(medicareCardOwners);

  useEffect(() => {
    setMemoizedMedicareCardOwners(medicareCardOwners);
  }, [medicareCardOwners]);

  useEffect(() => {
    const setOwnerInfo = async () => {
      const selectedOwner = memoizedMedicareCardOwners[selectedOwnerIndex];

      if (selectedOwner) {
        setValue(
          medicareCardFormFields.medicareCardIndividualRefNumber.fieldName,
          selectedOwner.id,
        );
        setValue(medicareCardFormFields.medicareCardFullName.fieldName, selectedOwner.name);
        trigger();
        onOwnerSelected({ id: selectedOwner.id, name: selectedOwner.name });
      }
    };
    setOwnerInfo();
  }, [selectedOwnerIndex, memoizedMedicareCardOwners, setValue, trigger, onOwnerSelected]);

  const handleChange = (index: number, key: string, value: string) => {
    setMemoizedMedicareCardOwners((medicareCardOwners) =>
      medicareCardOwners.map((owner, ownerIndex) => {
        if (index === ownerIndex) {
          return { ...owner, [key]: value };
        }
        return owner;
      }),
    );
  };

  const isNotSelectedOwner = selectedOwnerIndex < 0;

  if (medicareCardOwners.length < 1) {
    return;
  }

  // This situation is complex which impossible to resue our FormRadioGroup,
  // thus use MUI RadioGroup directly and mannually form control
  return (
    <Stack gap={tokens.spacingLg}>
      <Typography variant="h6">Select individual</Typography>
      {isNotSelectedOwner && (
        <DisplayAlertErrors
          variant="filled"
          errors={[{ message: 'Please select the individual' }]}
        />
      )}
      <RadioGroup
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: [tokens.spacing2xl, tokens.spacing2xl, tokens.spacingLg],
          '& > .MuiFormControlLabel-root > .MuiFormControlLabel-label': {
            width: '100%',
          },
        }}
        value={selectedOwnerIndex}
      >
        {memoizedMedicareCardOwners.map((owner, index) => (
          <FormControlLabel
            key={index}
            control={<Radio />}
            value={index}
            onClick={() => {
              setSelectedOwnerIndex(index);
            }}
            label={
              <Stack direction={['column', 'column', 'row']} width="100%" gap={tokens.spacingBase}>
                <TextField
                  label={medicareCardFormFields.medicareCardIndividualRefNumber.label}
                  value={owner.id}
                  fullWidth
                  required
                  error={!owner.id}
                  onChange={(e) => handleChange(index, 'id', e.target.value)}
                ></TextField>
                <TextField
                  value={owner.name}
                  label={medicareCardFormFields.medicareCardFullName.label}
                  required
                  fullWidth
                  error={!owner.name}
                  onChange={(e) => handleChange(index, 'name', e.target.value)}
                ></TextField>
              </Stack>
            }
          />
        ))}
      </RadioGroup>
    </Stack>
  );
};
