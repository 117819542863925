import { apiSchemas } from '@verifime/api-definition';
import { stringUtils, TObject } from '@verifime/utils';
import { z } from 'zod';

export type TMedicareCardOwners = {
  id: string;
  name: any;
}[];

export type TOcrData = z.infer<typeof apiSchemas.ScanDocumentApiResponse>;

export const SUPPORT_OCR_DOCUMENT_TYPES = ['DriverLicence', 'MedicareCard', 'Passport'] as const;
export type TSupportedOcrDocumentType = (typeof SUPPORT_OCR_DOCUMENT_TYPES)[number];

export const documentLabelMapping: { [documentType in TSupportedOcrDocumentType]: string } = {
  DriverLicence: "driver's licence",
  MedicareCard: 'medicare card',
  Passport: 'passport',
};

export function objectMedicareCardOwnersToArray(objOwners: TObject): TMedicareCardOwners {
  if (Object.keys(objOwners || {}).length < 1) {
    return [];
  }
  return Object.entries(objOwners).map(([id, name]) => ({ id, name }));
}

export function buildOcrData(
  documentType: TSupportedOcrDocumentType,
  formData: Record<string, any>,
): TOcrData {
  const lowerCaseFirstLetterDocumentType = stringUtils.lowerCaseFirstLetter(documentType);
  return {
    type: documentType,
    [lowerCaseFirstLetterDocumentType]: formData,
  };
}
