import { Box, Stack, Typography } from '@mui/material';
import { FlexPaper, NameValueDisplay } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { formatToDDMMMYYYYHHMMAAA } from '@verifime/utils';
import { TPermissionRequestDetail } from './types';

export default function ({
  organisationName,
  parentOrganisationName,
  completedDateTime,
  createdDateTime,
}: TPermissionRequestDetail) {
  return (
    <FlexPaper>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Typography variant="h6">{organisationName}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {parentOrganisationName}
          </Typography>
        </Box>
        <Stack padding={`${tokens.spacingXs} 0`}>
          <NameValueDisplay
            displayType="horizontal"
            name="Requested on"
            value={formatToDDMMMYYYYHHMMAAA(createdDateTime)}
          />
          {completedDateTime && (
            <NameValueDisplay
              displayType="horizontal"
              name="Completed on"
              value={formatToDDMMMYYYYHHMMAAA(completedDateTime)}
            />
          )}
        </Stack>
      </Stack>
    </FlexPaper>
  );
}
