'use client';

import { Box, CircularProgress, Stack, Step, StepLabel, Stepper, Theme } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

export type TStepNumber = number | 'first' | 'last' | 'both';

export type TStepContent = {
  /**
   * Step is 0-based number or one of 'first', 'last' and 'both'
   * step === 'frist' means this is the first steap
   * step === 'last' means this is the last step
   * step === 'both' means the step is the first and last, normally it happens when there is only one step
   */
  step: TStepNumber;
  numSteps: number;
  memoizedObj?: Record<string, any>;
  setMemoizedObj?: Dispatch<SetStateAction<Record<string, any>>>;
  onBack?: () => void;
  onNext?: () => void;
};

export type TStep = {
  content: (props: TStepContent) => ReactNode;
};

export type TSteps = TStep[];

export type TStepHeader = ({
  currentStep,
  memoizedObj,
  setMemoizedObj,
}: {
  currentStep: TStepNumber;
  memoizedObj: Record<string, any>;
  setMemoizedObj: Dispatch<SetStateAction<{}>>;
}) => ReactNode;

export default function StepsWizard({
  steps,
  defaultActiveStep = 0,
  header,
  isShowStepConnector = true,
  isShowStepLabels = true,
  prepare,
}: Readonly<{
  steps: TSteps | ((props: { memoizedObj: Record<string, any> }) => Promise<TSteps>);
  defaultActiveStep?: number;
  header?: ({
    currentStep,
    memoizedObj,
    setMemoizedObj,
  }: {
    currentStep: TStepNumber;
    memoizedObj: Record<string, any>;
    setMemoizedObj: Dispatch<SetStateAction<{}>>;
  }) => ReactNode;
  isShowStepConnector?: boolean;
  isShowStepLabels?: boolean;
  prepare?: ({ setMemoizedObj }: { setMemoizedObj: Dispatch<SetStateAction<{}>> }) => void;
}>) {
  const [memoizedObj, setMemoizedObj] = useState({});
  const [currentSteps, setCurrentSteps] = useState<TSteps>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isPreparing, setIsPreparing] = useState(true);

  useEffect(() => {
    const initializeSteps = async () => {
      const resolvedSteps = typeof steps === 'function' ? await steps({ memoizedObj }) : steps;
      setCurrentSteps(resolvedSteps);
      setActiveStep((prevStep) => {
        if (prevStep >= resolvedSteps?.length) {
          return Math.max(Math.min(defaultActiveStep, resolvedSteps.length - 1), 0);
        }
        return prevStep;
      });
    };
    initializeSteps();
  }, [steps, defaultActiveStep, memoizedObj]);

  useEffect(() => {
    if (typeof prepare !== 'function') {
      setIsPreparing(false);
      return;
    }
    prepare?.({ setMemoizedObj });
    setIsPreparing(false);
  }, [prepare]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const nextStep = prevActiveStep + 1;
      return Math.min(nextStep, currentSteps.length - 1);
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const prevStep = prevActiveStep - 1;
      return Math.max(prevStep, 0);
    });
  };

  if (currentSteps?.length < 1) {
    return null;
  }

  if (!currentSteps?.[activeStep]) {
    return null;
  }

  if (isPreparing) {
    return <CircularProgress />;
  }

  const activeStepContent = currentSteps[activeStep].content;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {header?.({
        currentStep: getCurrentStep(activeStep, currentSteps.length),
        memoizedObj,
        setMemoizedObj,
      })}
      {isShowStepConnector && (
        <Stack
          direction="row"
          alignContent="center"
          justifyContent="center"
          sx={{
            borderTop: `${tokens.spacing4xs} solid`,
            borderTopColor: (theme: Theme) => theme.palette.divider,
          }}
        >
          <Stepper
            activeStep={activeStep}
            sx={{
              width: '100%',
              maxWidth: [tokens.sizeSm, tokens.sizeSm, tokens.sizeSm, tokens.sizeSm, tokens.sizeMd],
              paddingTop: tokens.spacingBase,
            }}
          >
            {currentSteps.map((_, index) => {
              return <Step key={index}>{isShowStepLabels && <StepLabel />}</Step>;
            })}
          </Stepper>
        </Stack>
      )}

      <Box
        sx={{
          maxWidth: [tokens.sizeSm, tokens.sizeSm, tokens.sizeSm, tokens.sizeSm, tokens.sizeMd],
          margin: 'auto',
          height: '100%',
        }}
      >
        {activeStepContent({
          onBack: handleBack,
          onNext: handleNext,
          step: getCurrentStep(activeStep, currentSteps.length),
          numSteps: currentSteps.length,
          memoizedObj,
          setMemoizedObj,
        })}
      </Box>
    </Box>
  );
}

function getCurrentStep(activeStep: number, lenSteps: number) {
  if (lenSteps === 1) {
    return 'both';
  }
  if (activeStep === 0) {
    return 'first';
  }
  if (activeStep === lenSteps - 1) {
    return 'last';
  }
  return activeStep;
}
