import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Box, CircularProgress, Collapse, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DefaultFilePreview } from './DefaultFilePreview';
import { FileType, TFileUploaderProps, TUploadFile } from './types';
import { createUploadFile, formatFileSize, getFileTypeDisplay, isWildcardType } from './utils';

export const FileUploader: React.FC<TFileUploaderProps> = ({
  multiple = false,
  maxFileCount = multiple ? undefined : 1,
  maxFileSize,
  onUpload,
  onDelete,
  onBeforeUpload,
  initialFiles = [],
  hideUploaderAfterUpload = false,
  hideUploaderOnInitialFiles = false,
  onUploadSuccess,
  onUploadError,
  onFileRemove,
  onFileRemoveError,
  CustomPreview = DefaultFilePreview,
  CustomProgressBar,
  acceptedFileTypes = [FileType.ANY_IMAGE, FileType.PDF],
  onLimitReached,
}) => {
  const [files, setFiles] = useState<TUploadFile[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoadingInitialFiles, setIsLoadingInitialFiles] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attemptedFiles, setAttemptedFiles] = useState<File[]>([]);

  // Add a ref to track if initial load has happened
  const initialLoadComplete = useRef(false);

  // Calculate effective multiple value based on maxFileCount
  const effectiveMultiple = useMemo(() => {
    if (maxFileCount === 0) {
      return false;
    }
    return multiple;
  }, [multiple, maxFileCount]);

  // Calculate effective max files
  const effectiveMaxFiles = useMemo(() => {
    if (maxFileCount === 0) {
      return 0; // When maxFileCount is 0, no files allowed
    }
    if (!multiple) {
      return 1; // When multiple is false, only 1 file allowed
    }
    return maxFileCount || Infinity; // When multiple is true, use maxFileCount or unlimited
  }, [maxFileCount, multiple]);

  // Add a new useMemo to determine if upload is completely disabled
  const isUploadingDisabled = useMemo(() => {
    return maxFileCount === 0;
  }, [maxFileCount]);

  useEffect(() => {
    const loadInitialFiles = async () => {
      // Skip if already loaded or no initial files
      if (initialLoadComplete.current || initialFiles.length === 0) {
        setIsLoadingInitialFiles(false);
        return;
      }

      try {
        setIsLoadingInitialFiles(true);

        const initialFileObjects = await Promise.all(
          initialFiles.map(async ({ url, id, name }) => {
            try {
              const response = await fetch(url);
              if (!response.ok) {
                console.error(`Failed to load file ${name || url}: ${response.statusText}`);
                return null;
              }

              const blob = await response.blob();
              const fileName = name || url.split('/').pop() || 'file';
              const file = new File([blob], fileName, {
                type: blob.type,
              });
              const uploadFile = await createUploadFile(file);
              return {
                ...uploadFile,
                progress: 100,
                uploading: false,
                url: url,
                id: id ?? uploadFile.id,
              } as TUploadFile;
            } catch (error) {
              console.error(`Failed to load file ${name || url}:`, error);
              return null;
            }
          }),
        );

        // Filter out failed loads
        const validInitialObjects = initialFileObjects.filter(
          (obj): obj is TUploadFile => obj !== null,
        );

        // Only set valid files within limits
        const validFiles = validInitialObjects
          .filter((file) => !maxFileSize || file.file.size <= maxFileSize)
          .slice(0, effectiveMaxFiles);

        setFiles(validFiles);
        initialLoadComplete.current = true; // Mark initial load as complete
      } catch (error) {
        console.error('Load initial files failed:', error);
      } finally {
        setIsLoadingInitialFiles(false);
      }
    };

    loadInitialFiles();
  }, [initialFiles]); // Only depend on initialFiles

  // Clear error message after 5 seconds
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleError = (message: string) => {
    setErrorMessage(message);
    onUploadError?.(message);
  };

  // Update isUploadDisabled to include the new case
  const isUploadDisabled = useMemo(() => {
    if (isUploadingDisabled) {
      return true;
    }
    return effectiveMaxFiles ? files.length >= effectiveMaxFiles : false;
  }, [isUploadingDisabled, effectiveMaxFiles, files.length]);

  const validateFiles = (files: File[]): { valid: File[]; invalid: File[] } => {
    return files.reduce(
      (acc, file) => {
        const isValidType = isFileTypeAccepted(file, acceptedFileTypes);
        const isValidSize = maxFileSize ? file.size <= maxFileSize : true;

        if (!isValidType || !isValidSize) {
          acc.invalid.push(file);
        } else {
          acc.valid.push(file);
        }
        return acc;
      },
      { valid: [] as File[], invalid: [] as File[] },
    );
  };

  const handleFilesSelection = async (selectedFiles: File[]) => {
    if (!selectedFiles.length) return;

    // For single file upload, only take the first file
    const filesToProcess = multiple ? selectedFiles : [selectedFiles[0]];

    // If not multiple and trying to upload multiple files, show warning
    if (!multiple && selectedFiles.length > 1) {
      handleError(
        `Only single file upload is allowed. Selected first file: ${selectedFiles[0].name}. ` +
          `Skipped ${selectedFiles.length - 1} file${selectedFiles.length > 2 ? 's' : ''}.`,
      );
    }

    // Validate both file types and sizes
    const { valid, invalid } = validateFiles(filesToProcess);

    if (invalid.length > 0) {
      const invalidFileNames = invalid
        .map((file) => {
          const sizeIssue = maxFileSize && file.size > maxFileSize;
          const issue = sizeIssue
            ? `exceeds size limit (${formatFileSize(file.size)})`
            : 'invalid type';
          return `${file.name} (${issue})`;
        })
        .join(', ');

      if (valid.length === 0) {
        handleError(
          `Invalid file${invalid.length > 1 ? 's' : ''}: ${invalidFileNames}. ` +
            `Accepted types: ${acceptedFileTypes.map(getFileTypeDisplay).join(', ')}` +
            (maxFileSize ? ` • Max size: ${formatFileSize(maxFileSize)}` : ''),
        );
        return;
      } else {
        handleError(`Some files will be skipped: ${invalidFileNames}`);
      }
    }

    if (valid.length === 0) return;

    const totalFiles = files.length + valid.length;
    const remainingSlots =
      effectiveMaxFiles === Infinity ? valid.length : effectiveMaxFiles - files.length;

    // Determine which files to upload based on limits
    const filesToUpload =
      effectiveMaxFiles !== Infinity && totalFiles > effectiveMaxFiles
        ? valid.slice(0, remainingSlots)
        : valid;

    // Show warning if some files will be skipped due to limits
    if (effectiveMaxFiles !== Infinity && totalFiles > effectiveMaxFiles) {
      const remainingFiles = valid.slice(remainingSlots);
      handleError(
        `Uploading first ${remainingSlots} file${remainingSlots === 1 ? '' : 's'}. ` +
          `Skipping ${remainingFiles.length} file${remainingFiles.length === 1 ? '' : 's'} ` +
          `due to ${maxFileCount ? `${maxFileCount} file limit` : 'file limit'}.`,
      );
    }

    // Validate with onBeforeUpload if provided
    if (onBeforeUpload) {
      const isValid = await onBeforeUpload(filesToUpload);
      if (!isValid) return;
    }

    // Create upload file objects and add them to state immediately with 0 progress
    const uploadFileObjects = await Promise.all(
      filesToUpload.map(async (file) => {
        const uploadFile = await createUploadFile(file);
        return {
          ...uploadFile,
          progress: 0,
          uploading: true,
          error: null,
        };
      }),
    );

    // Update state immediately to show previews
    setFiles((prevFiles) => {
      if (!multiple) {
        return uploadFileObjects;
      }
      return [...prevFiles, ...uploadFileObjects];
    });

    // Start the upload process
    await uploadFiles(uploadFileObjects);

    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isFileTypeAccepted = (file: File, acceptedTypes: FileType[]): boolean => {
    return acceptedTypes.some((acceptedType) => {
      // Handle wildcard types (e.g., 'image/*')
      if (isWildcardType(acceptedType)) {
        const mainType = acceptedType.split('/')[0];
        return file.type.startsWith(mainType);
      }
      return file.type === acceptedType;
    });
  };

  // Update the drag and drop handlers
  const handleDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);

      if (isUploadingDisabled) {
        return;
      }

      const droppedFiles = Array.from(e.dataTransfer.files);
      await handleFilesSelection(droppedFiles);
    },
    [isUploadingDisabled, handleFilesSelection],
  );

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    await handleFilesSelection(selectedFiles);
  };

  const handleUploadError = useCallback(
    (file: TUploadFile, error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';

      setFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        const fileIndex = newFiles.findIndex((f) => f.id === file.id);
        if (fileIndex !== -1) {
          newFiles[fileIndex] = {
            ...newFiles[fileIndex],
            progress: 0,
            uploading: false,
            error: errorMessage,
          };
        }
        return newFiles;
      });

      onUploadError?.(errorMessage);
      handleError(`Failed to upload ${file.file.name}: ${errorMessage}`);
    },
    [onUploadError, handleError],
  );

  // Effect for handling limit reached
  useEffect(() => {
    const checkLimits = () => {
      const successfulFiles = files.filter((f) => !f.error && !f.uploading);

      if (effectiveMaxFiles) {
        onLimitReached?.({
          isMaxFileCountReached: successfulFiles.length >= effectiveMaxFiles,
          isMaxFileSizeReached: false,
          uploadedFiles: successfulFiles,
          attemptedFiles,
          limits: {
            maxFileCount: effectiveMaxFiles,
            maxFileSize,
          },
        });
      }
    };

    checkLimits();
  }, [files, effectiveMaxFiles, maxFileSize, onLimitReached, attemptedFiles]);

  const uploadFiles = async (filesToUpload: TUploadFile[]) => {
    // Update attempted files for limit check
    setAttemptedFiles(filesToUpload.map((f) => f.file));

    const uploadPromises = filesToUpload.map(async (file) => {
      try {
        const handleProgress = (progress: number) => {
          setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            const fileIndex = newFiles.findIndex((f) => f.id === file.id);
            if (fileIndex !== -1) {
              newFiles[fileIndex] = {
                ...newFiles[fileIndex],
                progress,
                uploading: progress < 100,
              };
            }
            return newFiles;
          });
        };

        const { url, id } = await onUpload(file.file, handleProgress);

        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          const fileIndex = newFiles.findIndex((f) => f.id === file.id);
          if (fileIndex !== -1) {
            newFiles[fileIndex] = {
              ...newFiles[fileIndex],
              progress: 100,
              uploading: false,
              url,
              id: id ?? file.id,
              error: null,
            };
          }
          return newFiles;
        });

        return {
          ...file,
          progress: 100,
          uploading: false,
          url,
          id: id ?? file.id,
        };
      } catch (error) {
        handleUploadError(file, error);
        return null;
      }
    });

    const uploadedFiles = (await Promise.all(uploadPromises)).filter(
      (file): file is NonNullable<typeof file> => file !== null,
    );

    if (uploadedFiles.length > 0) {
      onUploadSuccess?.(uploadedFiles);
    }

    // Just update files state without calling handleLimitReached directly
    setFiles((currentFiles) => currentFiles);
  };

  const handleFileRemove = async (file: TUploadFile) => {
    try {
      if (onDelete) {
        await onDelete(file);
        onFileRemove?.(file);
      }

      // Directly update files state
      setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id));

      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      onFileRemoveError?.(error instanceof Error ? error.message : 'Unknown error occurred');
    }
  };

  const shouldHideUploader = useMemo(() => {
    // Case 1: hideUploaderAfterUpload = false, hideUploaderOnInitialFiles = false
    if (!hideUploaderAfterUpload && !hideUploaderOnInitialFiles) {
      return false;
    }

    // Case 2: hideUploaderAfterUpload = false, hideUploaderOnInitialFiles = true
    if (!hideUploaderAfterUpload && hideUploaderOnInitialFiles) {
      const effectiveMaxFiles = effectiveMultiple ? maxFileCount : 1;
      const hasReachedLimit = effectiveMaxFiles ? files.length >= effectiveMaxFiles : false;
      // If we have no files or haven't reached the limit, show uploader
      if (files.length === 0 || !hasReachedLimit) {
        return false;
      }
      // Check if we only have initial files (no uploads have happened)
      const hasOnlyInitialFiles = files.every((file) =>
        initialFiles.some((init) => init.url === file.url),
      );
      // Hide uploader only if we have only initial files and have reached the limit
      return hasOnlyInitialFiles && hasReachedLimit;
    }

    // Case 3: hideUploaderAfterUpload = true, hideUploaderOnInitialFiles = false
    if (hideUploaderAfterUpload && !hideUploaderOnInitialFiles) {
      const effectiveMaxFiles = effectiveMultiple ? maxFileCount : 1;
      const hasReachedLimit = effectiveMaxFiles ? files.length >= effectiveMaxFiles : false;

      // Check if we have any non-initial files (user uploaded files)
      const userUploadedFiles = files.filter(
        (file: TUploadFile) => !initialFiles.some((init: { url: string }) => init.url === file.url),
      );

      // Only hide if we have user-uploaded files AND they've reached the limit
      return userUploadedFiles.length > 0 && hasReachedLimit;
    }

    // Case 4: hideUploaderAfterUpload = true, hideUploaderOnInitialFiles = true
    if (hideUploaderAfterUpload && hideUploaderOnInitialFiles) {
      const effectiveMaxFiles = effectiveMultiple ? maxFileCount : 1;
      const hasReachedLimit = effectiveMaxFiles ? files.length >= effectiveMaxFiles : false;

      // Always hide if we've reached the limit, regardless of initial or uploaded files
      if (hasReachedLimit) {
        return true;
      }

      // If we haven't reached the limit, show uploader
      return false;
    }

    return false;
  }, [
    hideUploaderAfterUpload,
    hideUploaderOnInitialFiles,
    files,
    initialFiles,
    effectiveMultiple,
    maxFileCount,
  ]);

  // Add a new useMemo for the file count message
  const fileCountMessage = useMemo(() => {
    if (maxFileCount === 0) {
      return null;
    }
    if (!multiple) {
      return `${files.length} of 1 file uploaded`;
    }
    if (!maxFileCount) {
      return `${files.length} files uploaded`; // No limit when multiple is true and no maxFileCount
    }
    return `${files.length} of ${maxFileCount} files uploaded`; // Show limit when maxFileCount is specified
  }, [files.length, maxFileCount, multiple]);

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={Boolean(errorMessage)}>
        <Alert
          severity="error"
          sx={{ mb: tokens.spacingBase }}
          onClose={() => setErrorMessage(null)}
        >
          {errorMessage}
        </Alert>
      </Collapse>

      {/* File uploader section - always show unless explicitly hidden */}
      {!shouldHideUploader && (
        <Box
          sx={{
            borderStyle: 'dashed',
            borderWidth: tokens.spacing3xs,
            borderColor: isDragging ? 'primary.main' : 'divider',
            borderRadius: tokens.borderRadiusSm,
            p: tokens.spacingBase,
            position: 'relative',
            mb: files.length > 0 ? tokens.spacingBase : 0,
            opacity: isUploadingDisabled ? 0.5 : 1,
            cursor: isUploadingDisabled ? 'not-allowed' : 'default',
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
            multiple={multiple}
            accept={acceptedFileTypes.join(',')}
            disabled={isUploadingDisabled}
          />

          <Box
            onDrop={handleDrop}
            onDragOver={(e) => {
              e.preventDefault();
              !isUploadDisabled && !isUploadingDisabled && setIsDragging(true);
            }}
            onDragLeave={() => setIsDragging(false)}
            onClick={() =>
              !isUploadDisabled && !isUploadingDisabled && fileInputRef.current?.click()
            }
            sx={{
              p: 3,
              textAlign: 'center',
              bgcolor: isDragging ? 'action.hover' : 'background.paper',
              cursor: isUploadDisabled || isUploadingDisabled ? 'not-allowed' : 'pointer',
              opacity: isUploadDisabled || isUploadingDisabled ? 0.6 : 1,
              pointerEvents: isUploadDisabled || isUploadingDisabled ? 'none' : 'auto',
            }}
          >
            <CloudUploadIcon
              sx={{
                fontSize: tokens.fontSize7xl,
                color: isUploadDisabled || isUploadingDisabled ? 'grey.500' : 'primary.main',
                mb: tokens.spacingXs,
              }}
            />
            <Typography
              color={isUploadDisabled || isUploadingDisabled ? 'text.disabled' : 'text.primary'}
            >
              {isUploadingDisabled
                ? 'File upload is disabled'
                : isUploadDisabled
                ? maxFileCount === 0
                  ? 'File upload is not allowed'
                  : `Maximum ${maxFileCount} files reached. Remove files to upload more.`
                : 'Drag and drop files here or click to select files'}
            </Typography>
            {fileCountMessage && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: 'block', mt: tokens.spacing2xs }}
              >
                {fileCountMessage}
              </Typography>
            )}
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: 'block', mt: tokens.spacing2xs }}
            >
              Accepted types: {acceptedFileTypes.map(getFileTypeDisplay).join(', ')}
              {maxFileSize && ` • Max size: ${formatFileSize(maxFileSize)}`}
            </Typography>
          </Box>
        </Box>
      )}

      {/* File previews section - show loading state for initial load */}
      {isLoadingInitialFiles && (
        <Stack
          marginTop={tokens.spacingBase}
          direction="row"
          alignItems="center"
          gap={tokens.spacingXs}
        >
          <CircularProgress size={24} />
          <Typography variant="body2" color="text.secondary">
            Loading file...
          </Typography>
        </Stack>
      )}

      {/* Render actual files */}
      {!isLoadingInitialFiles && files.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: tokens.spacingBase,
          }}
        >
          {files.map((file) => (
            <CustomPreview
              key={file.id}
              file={file}
              onRemove={() => handleFileRemove(file)}
              CustomProgressBar={CustomProgressBar}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
