import { RenderType, TFormFieldsAndRules_New, TFromFieldInfo_New } from '@verifime/components';

import { api } from '@verifime/api-definition';
import { COUNTRY_STATES, TOption, validationRules } from '@verifime/utils';
import * as yup from 'yup';
import { TSupportedOcrDocumentType } from './utils';

/**
 * TODO: Needs to clean code further
 */

// Driver License
export type TDriverLicenceFields =
  | 'licenceName'
  | 'licenceCountryOfIssue'
  | 'licenceState'
  | 'licenceNumber'
  | 'licenceCardNumber'
  // | 'licenceVersionNumber' // Temporary remove, will add it in another PR with country configs
  | 'licenceExpiry';

export const driverLicenceFormFields: { [field in TDriverLicenceFields]: TFromFieldInfo_New } = {
  licenceName: {
    label: 'Licence Holder name',
    fieldName: 'licenceName',
    renderType: RenderType.Text,
    rules: validationRules.REQUIRED_STRING_RULES,
  },
  licenceCountryOfIssue: {
    label: 'Country of issue',
    fieldName: 'licenceCountryOfIssue',
    rules: validationRules.REQUIRED_STRING_RULES,
    renderType: RenderType.ComboBox,
    optionProvider: (value: string) =>
      api
        .getV1referencecountriessearch({ queries: { term: value } })
        .then((data) => data.map((country) => ({ code: country.alpha3, label: country.name }))),
  },

  licenceState: {
    label: 'State',
    fieldName: 'licenceState',
    renderType: RenderType.Select,
    rules: yup.mixed().when(['licenceCountryOfIssue'], ([countryOfIssueCode]) => {
      return validationRules.countryStateSelectInputRule(countryOfIssueCode);
    }),
    items: [
      {
        label: 'Not Applicable',
        code: '',
      },
      ...COUNTRY_STATES.AUS,
    ],
  },
  licenceNumber: {
    label: 'Licence number',
    fieldName: 'licenceNumber',
    renderType: RenderType.Text,
    rules: validationRules.REQUIRED_DRIVER_LICENCE_NUMBER_VALIDATION_RULES,
  },
  licenceCardNumber: {
    label: 'Licence Card Number (AU)',
    fieldName: 'licenceCardNumber',
    renderType: RenderType.Text,
    rules: validationRules.OPTIONAL_DRIVER_LICENCE_CARD_NUMBER_VALIDATION_RULES,
  },
  // licenceVersionNumber: {
  //   label: 'Licence Version Number (NZ)',
  //   fieldName: 'licenceVersionNumber',
  //   renderType: RenderType.Text,
  //   rules: validationRules.OPTIONAL_DRIVER_LICENCE_VERSION_NUMBER_VALIDATION_RULES,
  // },
  licenceExpiry: {
    label: 'Licence expiry',
    fieldName: 'licenceExpiry',
    renderType: RenderType.Date,
    rules: validationRules.REQUIRED_EXPIRY_DATE_VALIDATION_RULES,
  },
};

// Medicare Card
export const MEDICARE_CARD_COLOURS = ['Green', 'Blue', 'Yellow'];
export type TMedicareCardFields =
  | 'medicareCardNumber'
  | 'medicareCardColour'
  | 'medicareCardExpiry'
  | 'medicareCardIndividualRefNumber'
  | 'medicareCardFullName';

export const medicareCardFormFields: { [field in TMedicareCardFields]: TFromFieldInfo_New } = {
  medicareCardNumber: {
    label: 'Medicare Card Number',
    fieldName: 'medicareCardNumber',
    renderType: RenderType.Text,
    rules: validationRules.requiredStringRule({ min: 10, max: 11 }),
  },
  medicareCardColour: {
    label: 'Medicare Card Colour',
    fieldName: 'medicareCardColour',
    rules: validationRules.REQUIRED_STRING_RULES,
    renderType: RenderType.Select,
    items: MEDICARE_CARD_COLOURS.map((colour) => ({ code: colour, label: colour } as TOption)),
  },
  medicareCardExpiry: {
    label: 'Medicare Card Expiry',
    fieldName: 'medicareCardExpiry',
    renderType: RenderType.Date,
    rules: validationRules.requiredDateRule(),
    minDate: new Date(),
  },
  medicareCardIndividualRefNumber: {
    label: 'Individual Reference Number',
    fieldName: 'medicareCardIndividualRefNumber',
    rules: validationRules.REQUIRED_STRING_RULES,
    renderType: RenderType.Text,
  },
  medicareCardFullName: {
    label: 'Full Name (as it appears on card)',
    fieldName: 'medicareCardFullName',
    rules: validationRules.REQUIRED_FULL_NAME_VALIDATION_RULES,
    renderType: RenderType.Text,
  },
};

export const medicareCardFieldsAndRules: TFormFieldsAndRules_New = {
  row1: [medicareCardFormFields.medicareCardNumber],
  row2: [medicareCardFormFields.medicareCardColour],
  row3: [medicareCardFormFields.medicareCardExpiry],
};

export const medicareCardOwnersFieldsAndRules: TFormFieldsAndRules_New = {
  row4: [medicareCardFormFields.medicareCardIndividualRefNumber],
  row5: [medicareCardFormFields.medicareCardFullName],
};

// Passport
export type TPassportFields =
  | 'passportNumber'
  | 'passportName'
  | 'passportCountryOfIssue'
  | 'passportExpiry';

export const passportFormFields: { [field in TPassportFields]: TFromFieldInfo_New } = {
  passportNumber: {
    label: 'Passport Number',
    fieldName: 'passportNumber',
    renderType: RenderType.Text,
    rules: validationRules.REQUIRED_STRING_RULES,
  },
  passportName: {
    label: 'Name on Your Passport',
    fieldName: 'passportName',
    renderType: RenderType.Text,
    rules: validationRules.REQUIRED_STRING_RULES,
  },
  passportCountryOfIssue: {
    label: 'Country of Issue',
    fieldName: 'passportCountryOfIssue',
    rules: validationRules.REQUIRED_STRING_RULES,
    renderType: RenderType.ComboBox,
    optionProvider: (value: string) =>
      api
        .getV1referencecountriessearch({ queries: { term: value } })
        .then((data) => data.map((country) => ({ code: country.alpha3, label: country.name }))),
  },
  passportExpiry: {
    label: 'Expiry Date',
    fieldName: 'passportExpiry',
    renderType: RenderType.Date,
    rules: validationRules.REQUIRED_EXPIRY_DATE_VALIDATION_RULES,
  },
};
export type TOcrFormFields = TDriverLicenceFields | TMedicareCardFields | TPassportFields;

const formFieldConfigMap: Record<TSupportedOcrDocumentType, Record<string, TFromFieldInfo_New>> = {
  DriverLicence: driverLicenceFormFields,
  MedicareCard: medicareCardFormFields,
  Passport: passportFormFields,
};

/**
 * TODO: Needs to support to generate form fields and rules by countries, such as driver's licence is different between AUS and NZL
 */
export const generateFormFieldsAndRules = (
  documentType: TSupportedOcrDocumentType,
  ocrData: Record<string, any>,
): TFormFieldsAndRules_New => {
  const formFields = formFieldConfigMap[documentType];

  const result = Object.keys(formFields).reduce((formFieldsAndRules, key, index) => {
    const field = formFields[key];
    return {
      ...formFieldsAndRules,
      [`row${index}`]: [field],
    };
  }, {} as TFormFieldsAndRules_New);
  return result;
};
