import { CircularProgress, Stack, Typography } from '@mui/material';
import { api, TPerson } from '@verifime/api-definition';
import { DisplayAlertErrors } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { stringUtils, usePolling } from '@verifime/utils';
import { memo, ReactNode, useEffect, useState } from 'react';

import { CheckYourFiles } from './CheckYourFiles';
import { IdScanComplete } from './IdScanComplete';
import { OcrDocumentEditor } from './OcrDocumentEditor';
import { OcrMedicareCard } from './OcrMedicareCard';
import { buildOcrData, TOcrData, TSupportedOcrDocumentType } from './utils';

const FAILED_SCAN_MESSAGE = 'Failed to scan the document(s), please re-upload.';

type OcrEditorProps = Readonly<{
  customer: TPerson;
  documentType: TSupportedOcrDocumentType;
  onFormChange?: (isValid: boolean, data: TOcrData) => void;
}>;

export const OcrEditor = memo(({ customer, documentType, onFormChange }: OcrEditorProps) => {
  const [ocrData, setOcrData] = useState<TOcrData | null>(null);
  const [isLoadingOcr, setIsLoadingOcr] = useState(true);
  const [loadingOcrError, setLoadingOcrError] = useState<ReactNode | null>(null);
  const [ocrFilesCheckStatus, setOcrFilesCheckStatus] = useState<'NONE' | 'NO_FILES' | 'READY'>(
    'NONE',
  );

  const { stopPolling } = usePolling({
    callback: () => {
      api
        .getV1fileEntityIdDocType({
          params: { docType: documentType, entityId: customer.id },
        })
        .then((data) => {
          if (data.itemList?.length > 0) {
            stopPolling();
            setOcrFilesCheckStatus('READY');
          }
        })
        .catch((error) => console.error(error));
    },
    pollingIntervalInMs: 3000,
    maxRetry: 3,
    onMaxRetryReached: () => {
      setOcrFilesCheckStatus('NO_FILES');
      setLoadingOcrError(FAILED_SCAN_MESSAGE);
      setIsLoadingOcr(false);
    },
  });

  useEffect(() => {
    const loadOcr = async () => {
      setIsLoadingOcr(true);
      api
        .getV1documentscanresultcustomerCustomerIdidentityDocumentDocumentTypescan({
          params: { customerId: customer.id, documentType },
        })
        .then(setOcrData)
        .catch((error) => {
          // TODO: should consolidate error responseded
          if (error.code === 500) {
            setLoadingOcrError(<CheckYourFiles documentType={documentType} />);
          } else if (error.code === 'ERR_BAD_REQUEST') {
            setLoadingOcrError(FAILED_SCAN_MESSAGE);
          } else {
            setLoadingOcrError(error.message);
          }
        })
        .finally(() => {
          setIsLoadingOcr(false);
        });
    };
    const documentFieldName = stringUtils.lowerCaseFirstLetter(documentType);
    if (documentFieldName in customer) {
      const ocrData = buildOcrData(documentType, customer[documentFieldName]);
      if (documentType === 'MedicareCard') {
        ocrData.medicareCardOwners = {};
      }
      setOcrData(ocrData);
      setIsLoadingOcr(false);
    } else {
      // We load ocr files scanning only when there are uploaded ocr files
      if (ocrFilesCheckStatus !== 'READY') {
        return;
      }
      loadOcr();
    }
  }, [customer, documentType, ocrFilesCheckStatus]);

  const isSameTypeDocument = documentType === ocrData?.type;

  if (!customer || !documentType) {
    return;
  }

  if (isLoadingOcr) {
    return (
      <Stack direction="row" gap={tokens.spacingBase}>
        <CircularProgress color="inherit" size={20} />
        <Typography>Loading OCR...</Typography>
      </Stack>
    );
  }

  if (loadingOcrError) {
    return <DisplayAlertErrors errors={[{ message: loadingOcrError }]} />;
  }

  /**
   * TODO: Should allow user manually enter form fields when no ocr data responded.
   */
  if (!ocrData) {
    return;
  }

  if (!isSameTypeDocument) {
    return (
      <DisplayAlertErrors errors={[{ message: <CheckYourFiles documentType={documentType} /> }]} />
    );
  }

  return (
    <Stack gap={tokens.spacingBase}>
      <IdScanComplete />
      {documentType === 'MedicareCard' ? (
        <OcrMedicareCard
          initialData={ocrData}
          documentType={documentType}
          onFormChange={onFormChange}
        />
      ) : (
        <OcrDocumentEditor
          initialData={ocrData}
          documentType={documentType}
          onFormChange={onFormChange}
        />
      )}
    </Stack>
  );
});

OcrEditor.displayName = 'OcrEditor';
