import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { TProgressBarProps } from './types';

export default function DefaultProgressBar({ progress, isUploaded }: Readonly<TProgressBarProps>) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={progress}
        size={60}
        sx={{ color: isUploaded ? 'success.main' : 'primary.main' }}
      />
      <Stack
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" color="primary.main">{`${Math.round(progress)}%`}</Typography>
      </Stack>
    </Box>
  );
}
