import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export default function usePolling({
  callback,
  autoStart = true,
  pollingIntervalInMs = 1000,
  maxRetry = Number.MAX_VALUE,
  onMaxRetryReached,
}: {
  callback: () => void;
  autoStart?: boolean;
  pollingIntervalInMs?: number;
  maxRetry?: number;
  onMaxRetryReached?: () => void;
}) {
  const [isKeepPolling, setIsKeepPolling] = useState(autoStart);
  const [retryCount, setRetryCount] = useState(0);
  const timerRef = useRef<NodeJS.Timer>();

  const startPolling = useCallback(() => {
    setIsKeepPolling(true);
  }, []);

  const stopPolling = useCallback(() => {
    setIsKeepPolling(false);
  }, []);

  const isReachedRetryLimitation = useMemo(() => retryCount >= maxRetry, [retryCount, maxRetry]);

  useEffect(
    function checkRetryLimitation() {
      if (isReachedRetryLimitation) {
        setIsKeepPolling(false);
        onMaxRetryReached?.();
      }
    },
    [isReachedRetryLimitation, onMaxRetryReached],
  );

  useEffect(() => {
    if (!isKeepPolling) {
      clearInterval(timerRef.current);
      return;
    }

    if (isReachedRetryLimitation) {
      clearInterval(timerRef.current);
      return;
    }

    timerRef.current = setInterval(() => {
      setRetryCount((retryCount) => retryCount + 1);
      callback();
    }, pollingIntervalInMs);

    return () => clearInterval(timerRef.current);
  }, [isKeepPolling, callback, pollingIntervalInMs, isReachedRetryLimitation]);

  return { startPolling, stopPolling };
}
